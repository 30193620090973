// store.js
// import { addMinutes } from 'date-fns';

import Vue from 'vue';

// HOW To USE IT
//import { getters, zRoute } from "@/api/store";
//getZml: getters.getState({ object: "gZml" }),
//storage = zRoute.fetch(this.route)


//getZml.folders = folderid sortorder foldername realfoldername subjectid grade icon create_timestamp update_timestamp
//getZml.subjects = id,name, subjectid,sortorder,shortname,path,description,beskrywing,linksubjectid,picture
const state = Vue.observable({
    gOth: {popi:[],
           atester: false,
           store: {totalItems: 0},
           folders: [],
           routing:[],
    },
    gZml: {login: {isAuthenticated: 0
                  , grade:'G12'
                  , class:'12'
                  , gclass:''
                  , lang:'E'
                  , fullname:''
                  , surname:''
                  , firstname:''
                  , username:''
                  , phone:''
                  , email:''
                  , studentid:''
                  , schoolno:0
                  , userid:''
                  , persid:''
                  , menemonic:''
                  , lastdate:''
                  , type:'guest'
                  , login:''
                  , superUser: false
                  , initSurname:''
                  , workArea:''
                  , workEmail:''},
           grade: '',
           grades:[{id:8 ,text: "G08",name: 'Grade 8'} ,{id:9 ,text: "G09",name: 'Grade 9'}
                  ,{id:10,text: "G10",name: 'Grade 10'},{id:11,text: "G11",name: 'Grade 11'}
                  ,{id:12,text: "G12",name: 'Grade 12'},{id:'INFO',text: "Info",name: 'Info'}
                  ],
           subject: '',
           subjectid: '',
           subjects: [], //subjectid, sortorder, shortname, path, beskrywing description, description beskrywing, linksubjectid, picture, color
           functions: [], //create_timestamp,description, functionaccess=addmin,functionid,functionname,functiontype=local,grade,icon,payload,shortname,sortorder,tip,update_timestamp
           voteList:[],
           locale: 'en',
           calendar:[],
           baseCalendar:[], //day  dayOfWeek day_name dayno fulldate holiday_flag idDate month month_name quarter week weekend year 2024
           //meritLevel:[{id:10, back:10, forward: 20, points:0, title:"hllo", description:"jsdfsdf"}],
           persMenemonic:[],   //userid:"144", persid: "144", user_name: "JBRES", "user_fullname": "Me. J. Bresler", "surname": "Bresler", name,title,workemail,"room": "Sport 2", "user_type": "teacher"
           place: [],  //p.placeid, p.name, w.name workarea, p.description,p.ownerid concat(p.name, ' - ',w.name) concatsearch"
           classList: [],   //id, teacher (init + surname),listname, share,jdocstructure,create_timestamp,update_timestamp, grade,ckey,hodsubjectid
           tickList: [],
           students: [],  //studentid, name, surname, grade class
           thisday: null,
           routes:[],
           look:{
            stfName: (menemonic) => {
              //https://www.geeksforgeeks.org/convert-an-array-to-an-object-in-javascript/
              if (!menemonic) return ''
              const user = state.gZml.persMenemonic.filter(e => e.user_name == menemonic)
              let userObj = {}
              if (user.length) {
                userObj = user[0]
              } else {
                console.error('stfName - we have problem doing that lookup')
                return "Ms. P Belinda"
              }
              return userObj.user_fullname
            },
            stfObject: (menemonic) => {
              if (!menemonic) return ''
              const user = state.gZml.persMenemonic.filter(e => e.user_name == menemonic)
              let userObj = {}
              if (user.length) {  userObj = user[0]} else { console.error('stfEmail - we have problem doing that lookup');return {error:'Not Found'}}
              return userObj
            }
           }
          }
    });

   // mutations.setState({ object: "gZml", objectPath: login.class })
    //mutations.setState( {object: "gZml", objectPath: [id, "classList"], value: whatever} );
const mutations = {
  setState({ object, objectPath, value, upsert = false } = {}) {
    if (state[object] === undefined || value === undefined)
      console.error("setState: Invalid Object or Value");
    if (objectPath === undefined) state[object] = value;
    if (objectPath && Array.isArray(objectPath) && objectPath.length) {
      let navigate = [object, ...objectPath.slice(0, -1)],
        valueObj = navigate.reduce((obj, prop) => {
          if (typeof obj[prop] !== "object") {
            if (upsert) {
              obj[prop] = {};
            } else {
              console.error(`setState: property '${prop}' doesn't exist`);
            }
          }
          return obj[prop];
        }, state);
      Vue.set(valueObj, objectPath[objectPath.length - 1], value);
    }
  }
  // other specific mutations ...
};

const getters = {
  getState({ object, objectPath } = {}) {
    if (state[object] === undefined) console.error("getState: Invalid Object.", object, objectPath);
    if (objectPath === undefined) return state[object];
    if (objectPath && Array.isArray(objectPath) && objectPath.length) {
      let navigate = [object, ...objectPath.slice(0, -1)],
        valueObj = navigate.reduce((obj, prop) => {
          if (obj[prop] === undefined) {
            console.error(`getState: property '${prop}' doesn't exist`);
          }
          return obj[prop];
        }, state),
        value = valueObj[objectPath[objectPath.length - 1]];
      if (value === undefined) console.error(`getState: Invalid object path`);
      return value;
    }
  }
  // other specific getters ...
};

import { errorSnackbar } from "@/api/GlobalActions"


//How to use it on sending side...
/*
      let name = 'nameOfRoute'
      let pIdx = zRoute.load(name,
                            { csvTitle: this.parms.csvTitle || 'Data List prepared by DKLearn'
                            , jsonData: this.finalJsonData
                            , labels:this.labels
                            , showLabels:true
                            , seperator: ';'
                            })
      this.$router.push(zRoute.run(name, pIdx))
*/

const zRoute = {
  cl: function (...args) {
      console.info('zRoute',...args);
  },
  storage: state.gOth.routing,
  pIdx: -1,
  load: function(routeName, parms) {
    this.pIdx = this.storage.length + 1
    this.storage[this.pIdx] = parms
    if (this.pIdx < 0) alert('Error assihning storage')
    this.cl('load', routeName, this.pIdx)
    return this.pIdx
  },
  run: function (routeName, pIdx) {
    this.cl('run', routeName, pIdx, this.pIdx)
    return { name: routeName, params: {route: pIdx} }
  },
  test: function (pIdx) {
    this.cl('test', pIdx, this.pIdx)
    if (pIdx > this.pIdx || !this.storage[pIdx]) {
       return false
    }
    return true
  },
  snack: (msg) => errorSnackbar(msg),
  fetch: function (pIdx) {
    this.cl('fetch', pIdx, this.pIdx)
    if (!this.test(pIdx) ) {
      alert('Something is wrong - no data - only when we not test')
      this.snack('Something is wrong - no data - going back')
      return false
    }
    return this.storage[pIdx]
  },
}
export { getters, mutations, state, zRoute };
