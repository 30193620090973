<template>
<v-container grid-list-lg v-if="subjectList.length" >
  <z-span> StSubLst  {{ showPers }}</z-span>
  <v-dialog max-width="400" v-model="showPers" scrollable>
    <RealSignature v-if="menemonic"  :menemonic="menemonic" />
  </v-dialog>

   <v-toolbar dense row>
    <v-toolbar-title>
      <span class="d-none d-sm-block"> {{ reportOnFindings }} </span>
    </v-toolbar-title>
    <v-overlay v-if="loading" :value="loading">
      <v-progress-circular indeterminate size="84">
        Thinking ...
      </v-progress-circular>
    </v-overlay>
    <v-spacer />
     <v-btn class="ma-2" small icon @click="showAs='list'" title="View as list"> <v-icon>mdi-view-list</v-icon> </v-btn>
     <v-btn class="ma-2" small icon @click="showAs='card'" title="View as cards"> <v-icon>mdi-id-card</v-icon> </v-btn>
   </v-toolbar>

     <v-layout row wrap v-if="showAs == 'card'">
        <v-flex v-for="s in subjectList" :key="s.studsubid" xs12 sm6 md6 lg6>
          <v-card>
            <!--v-img src="img/logo.png" height="260px "></v-img-->
            <!-- <v-img class="float-right" width="100px" src="/img/logo.png" /> -->
            <v-card class="float-right ma-2" height="50" width="50"
                    @click="showIt(s.menemonic)" >
             <z-show name="persphoto"
                    :id="s.persid" />
            </v-card>

            <v-card-title primary-title class='blue--text wordbreak'>
              {{s.subjectname}}
            </v-card-title>
            <v-card-subtitle>
              {{s.teacher }} {{  s.listname }}
            </v-card-subtitle>
            <v-card-text class='body-2'>
              <span v-if="s.examdate"> ExamDate : {{ s.examdate }}<br>
                {{ s.subexam }}, {{ s.menemonic }}, venue: {{ s.venue }} </span>
              <!-- {{ s.ckey }} -->
                <!-- Next Exam : {{s.examdate}} -->
              <!-- M.S at {{teacher.ms}} <br> M.S.C at {{teacher.msc}} -->

            </v-card-text>

            <v-card-actions>
              <v-btn x-small outlined color='blue'
                     @click="showTeacher(`${s.teacher}`)"
                     :title="`Show ${s.teacher}`">
              More...
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn x-small outlined color='green' to="/appointment">
                Appointments
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      <v-simple-table v-if="showAs=='list'">
      <thead>
        <tr><th>teacher</th><th>subjectname</th></tr>
      </thead>
      <tbody>
      <tr v-for="s in subjectList" :key="s.studsubid" xs12 sm6 md6 lg6>
        <td> {{ s.teacher}}</td>
        <td> {{s.subjectname}}</td>
        <td> {{s.examdate}}</td>
        <td> {{s.listname}}</td>
        <td  v-if="superUser"> {{s.classlistid}}</td>
        <td v-if="superUser"> {{s.id}}</td>
      </tr>
      </tbody>
     </v-simple-table>
      <!-- {{ lookupPersid('smit','w') }} -->
 </v-container>
</template>

<script>
import { getters } from "@/api/store";
import { zmlConfig } from '@/api/constants';
import { zmlFetch } from '@/api/zmlFetch';
//import { finder } from '@/api/finder.js';
import ZShow from '@/components/base/ZShow.vue'
import RealSignature from '@/components/staff/RealSignature.vue'
export default {
    name:"StudentSubjectCard",
    props: ['studentid', 'color'],
    components: {
      ZShow,
      RealSignature
    },
    data: () => ({
      subjectList:{},
      persMenemonic: getters.getState({ object: "gZml" }).persMenemonic,
      superUser:getters.getState({ object: "gZml" }).login.superUser,
      menemonic: '',
      loading: false,
      showAs: 'list',
      showPers:false,
      reportOnFindings: 'Student Subjects'
    }),
    computed: {
    },
    methods:{
      showIt(menemonic) {
         this.showPers = true
         this.menemonic = menemonic
         //this.showTeacher(menemonic)
      },
      // showTeacher(menemonic) {
      //   console.log('pushing personel for ', menemonic)
      //   // this.$router.push({ path: `/signature/${menemonic}` })
      //   this.menemonic = menemonic
      // },
      loadStudentSubject() {
            this.subjectList.length = 0
            if (this.studentid) {
               this.loading = true
               let ts = {}
               ts.task = 'PlainSql'
      //          ts.sql = `select ss.studentid,ss.ckey, ss.subjectname , ss.hodsubjectid, ss.menemonic, g.subjectname subexam , g.venue, g.examdate, g.teacher\
      //          , ss.teacherinitial, ss.teachersurname\
      // from dkhs_studsub ss\
      // left join dkhs_student s on s.studentid = ss.studentid\
      // left join dkhs_subjectgroup g on s.grade = g.grade and concat(ss.subjectname,'.',ckey) = g.subjectname\
      //   and ss.teacher = g.teacher\
      // where ss.studentid = ${this.studentid}`

            ts.sql = `select s.grade, s.gclass, s.surname, s.studentid\
                  , ss.classlistid\
                  , cl.id\
                  , cl.listname, cl.hodsubjectid\
                  , cl.venue, cl.examdate\
                  , cl.teacher\
                  , if(substr(s.grade,1,1) = 'E', sub.description,sub.beskrywing) subjectname\
                  , pp.menemonic, pp.persid
               from dkhs_student s\
               join hw_classliststudent ss on s.studentid = ss.studentid \
               join hw_classlist cl on ss.classlistid = ifnull(cl.ckey,cl.id) AND share not in ('X','G')\
               join dkhs_lsubject sub on sub.subjectid = cl.hodsubjectid\
               left join dkhs_personel pp on pp.initsurname = cl.teacher\
              where cl.listname not like '%*%' \
                and s.studentid = ${this.studentid}
                order by subjectname`
               ts.api = zmlConfig.apiDKHS
               zmlFetch(ts, this.assignData);
            }
            //and cl.hodsubjectid not in (25,26)\
      },
      assignData(response){
        if (!response.error) {
             this.subjectList = response
             this.reportOnFindings = 'We found ' + this.subjectList.length + ' subjects'
          } else {
              this.subjectist = []
              this.reportOnFindings = 'We did not find any entries - '
          }
        this.subjectList = response
        this.loading = false
      },
    },
    mounted: function() {
        if (this.studentid) {
           this.loadStudentSubject()
        } else {
           this.subjectList.length = 0
        }
    },
    watch: {
        showPers() {
          if (!this.showPers) this.menemonic = ''
        },
        studentid(n,o) {
            if (n != o) this.loadStudentSubject()
        }
    }
}
</script>

<style scoped>
.wordbreak {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
</style>