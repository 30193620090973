import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import HomeStart from '@/views/home/HomeStart'
import login from '@/components/Login'

import { getters } from "@/api/store";
import { zmlLog } from '@/api/zmlLog.js';
import { staleCheck } from "@/api/localStorage.js"
function cl(...args) {    console.log('RT',...args) }

Vue.use(VueRouter)

const la = ["AppLayoutDefault","AppLayoutBasic","AppLayoutLinks","AppLayoutGray","AppLayoutDrawer"]

const routes = [
  /*
  { path: '/a', redirect: '/about' },
  { path: '/h', redirect: '/hover' },
  { path: '/1', redirect: { name: 'Werner' }},
  */
  {
    component: HomeStart,
    path: '/',
    name: 'HomeStart',
    meta: {layout: la[4],
    authentication: "public"}
  },
  {
    component: Home,
    path: '/home',
    name: 'Home',
    meta: {layout: la[4],
    authentication: "public"}
  },
  {
    component: login,
    path: '/login/:forgot?',
    name: 'Login',
    props:true,
    params: {errorMessage:''},
    meta: {layout: la[4], authentication: "public"}
  },
  { path: '/drive', redirect: '/folder' },
  {
    path: '/folder/:grade?/:subject?/:level1?/:level2?/:level3?/:level4?',
    name: 'Folder',
    component: () => import(/* webpackChunkName: "folder" */ '@/components/folder/FolderEdit.vue'),
    props:true,
    params: {grade: 'Gr08', subject: 'Accounting_Rekeningkunde'},
    meta: {layout: la[4], authentication: "student" }
  },
  {
    path: '/share/:grade?/:subject?/:level1?/:level2?/:level3?/:level4?',
    name: 'Share',
    component: () => import(/* webpackChunkName: "folder" */ '@/components/folder/FolderShare.vue'),
    props:true,
    params: {grade: 'Gr08', subject: 'Accounting_Rekeningkunde'},
    meta: {layout: la[4], authentication: "student" }
  },
  {
    path: '/virtualawards', redirect: '/virtualawards/27/0'
  }
 ,{
   //Actual award!
     path: '/virtualawards/:chapterid/:orderid?',    name: 'virtualawards',
    component: () => import(/* webpackChunkName: "awards" */ '@/views/awards/AwardCarousel.vue')
   , props: true,    params: {chapterid: 26, orderid: 1, editmode: false}
   , meta: {layout: la[0], authentication: "public" }
  },
  {
     path: '/loadhomework/:StudentID?'
    ,name: 'LoadHomework',
    component: () => import(/* webpackChunkName: "atten" */ '@/views/LoadHomework.vue')
    ,alias: '/homex'
    ,params: {studentID:'20113'}
    ,meta: {layout: la[4], authentication: "student"}
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Admin'),
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    path: '/dm',
    name: 'DialogMenu',
    component: () => import(/* webpackChunkName: "home" */ '@/views/DialogMenu.vue'),
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    path: '/calteach/:menemonic?',
    name: 'CalendarTeacher',
    props:true,
    params: {menemonic: 'SDVM'},
    component: () => import(/* webpackChunkName: "kal" */ '@/components/cal/CalendarPers.vue'),
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    path: '/kalender',
    name: 'Kalender',
    component: () => import(/* webpackChunkName: "kal" */ '@/components/cal/Kalender.vue'),
    meta: {layout: la[4], authentication: "public" }
  },
  // {
  //   component: () => import(/* webpackChunkName: "kal" */ '@/views/BaseCalenViewEdit.vue'),
  //   path: '/basecalendaredit/:seldate?',
  //   name: 'BaseCalenViewEdit',
  //   props:true,
  //   //params: {seldate:'2023-04'},
  //   meta: {layout: la[4], authentication: "admin" }
  // },
  // {
  //   path: '/calstud/:studentid?',
  //   name: 'CalStudent',
  //   props:true,
  //   params: {studentid: '20113'},
  //   component: () => import(/* webpackChunkName: "kal" */ '@/components/CalendarStudent'),
  //   meta: {layout: la[4], authentication: "student" }
  // },

  {
    path: '/choosesubjects',
    name: 'skool',
    component: () => import(/* webpackChunkName: "pub" */ '@/components/vfbase/skool'),
    meta: {layout: la[4], authentication: "public" }
  },
  {
    path: '/openday/:surveyidPassed?',
    name: 'openday',
    props:true,
    params: {surveyidPassed: '3'},
    component: () => import(/* webpackChunkName: "pub" */ '@/components/vfbase/register'),
    meta: {layout: la[0], authentication: "public" }
  },
  {
    path: '/rekenaar/:surveyidPassed?',
    name: 'rekenaar',
    props:true,
    params: {surveyidPassed: '4'},
    component: () => import(/* webpackChunkName: "pub" */ '@/components/vfbase/rekenaar.vue'),
    meta: {layout: la[0], authentication: "public" }
  },
  {
    path: '/appointment/:surveyidPassed?',
    name: 'appointment',
    props:true,
    params: {surveyidPassed: '6'},
    component: () => import(/* webpackChunkName: "pub" */ '@/components/vfbase/Appointment.vue'),
    meta: {layout: la[0], authentication: "public" }
  },
  {
    path: '/appointments/:menemonic?',
    name: 'appointments',
    props:true,
    params: {menemonic: ''},
    component: () => import(/* webpackChunkName: "pub" */ '@/components/vfbase/Appointments.vue'),
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    path: '/appview',
    name: 'appview',
    props:true,
    component: () => import(/* webpackChunkName: "pub" */ '@/components/vfbase/AppView.vue'),
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    path: '/pres/:surveyidPassed?',
    name: 'Presensie',
    props:true,
    params: {surveyidPassed: '5'},
    component: () => import(/* webpackChunkName: "pub" */ '@/components/vfbase/Presensie.vue'),
    meta: {layout: la[0], authentication: "public" }
  },
  {
    component: () => import(/* webpackChunkName: "pub" */ '@/components/vfbase/basicLoopTestWithAwait.vue'),
    path: '/testloop', name: 'TestLoop',
    meta: {layout: la[4], authentication: "werner" }
  },
  {
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
    path: '/about', name: 'About',
    meta: {layout: la[4], authentication: "werner" }
  },
  {
    component: () => import(/* webpackChunkName: "about" */ '@/views/quanda.vue'),
    path: '/quanda', name: 'FAQ',
    meta: {layout: la[4], authentication: "werner" }
  },
  {
    component: () => import(/* webpackChunkName: "about" */ '@/test/addFaq.vue'),
    path: '/addfaq/faqid?', name: 'addFaq',
    params: {faqid: 0},
    meta: {layout: la[4], authentication: "werner" }
  },
  {
    component: () => import(/* webpackChunkName: "about" */ '@/components/picload/Enroll.vue'),
    path: '/enroll', name: 'Enroll',
    meta: {layout: la[0], authentication: "public" }
  },
  {
    component: () => import(/* webpackChunkName: "about" */ '@/test/addUpload.vue'),
    path: '/addphoto', name: 'addUpload',
    meta: {layout: la[0], authentication: "student" }
  },
  {
    component: () => import(/* webpackChunkName: "about" */ '@/test/test7Up.vue'),
    path: '/test7', name: 'test7Up',
    meta: {layout: la[4], authentication: "werner" }
  },
  {
    component: () => import(/* webpackChunkName: "about" */ '@/test/schemaCRUD.vue'),
    path: '/testCrud', name: 'schemaCRUD',
    meta: {layout: la[4], authentication: "werner" }
  },
  {
    path: '/periodtable/:periodDescription?',
    name: 'PeriodTable',
    props:true,
    params: {periodDescription: 'Monday'},
    component: () => import(/* webpackChunkName: "kal" */ '@/components/cal/PeriodTable.vue'),
    meta: {layout: la[0], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "kal" */ '@/components/cal/PeriodListView.vue'),
    path: '/periodview',
    name: 'PeriodListView',
    props:true,
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "kal" */ '@/components/cal/EventTypeView.vue'),
    path: '/eventtype',
    name: 'EventTypeView',
    props:true,
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "kal" */ '@/components/cal/EventFormText.vue'),
    path: '/evtxt',
    name: 'EventFormText',
    props:true,
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "kal" */ '@/components/cal/EventListView.vue'),
    path: '/eventlist',
    name: 'EventListView',
    props:true,
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    path: '/rooster/:user_name?',
    name: 'Rooster',
    props:true,
    params: {user_name: 'TVRB'},
    component: () => import(/* webpackChunkName: "kal" */ '@/components/learn/rooster.vue'),
    meta: {layout: la[0], authentication: "teacher" }
  },
  {
    path: '/systemview',
    name: 'SystemView',
    component: () => import(/* webpackChunkName: "test" */ '@/views/SystemView.vue'),
    meta: {layout: la[0], authentication: "admin" }
  },
  {
    path: '/links',
    name: 'ShortLinks',
    component: () => import(/* webpackChunkName: "test" */ '@/views/ShortLinks.vue'),
    meta: {layout: la[4], authentication: "admin" }
  },

  {
    component: () => import(/* webpackChunkName: "test" */ '@/components/FundRaiser.vue')
    ,name: 'FundRaiser'
    ,path: '/thermometer'
    ,meta: {layout: la[0], authentication: "public"}
  },
  {
    path: '/repexp',
    name: 'BasePrintTable',
    component: () => import(/* webpackChunkName: "test" */ '@/components/base/BasePrintTable.vue'),
    props:true,
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    path: '/cemismenu',
    name: 'Cemis',
    component: () => import(/* webpackChunkName: "test" */ '@/test/Cemis.vue'),
    props:true,
    meta: {layout: la[0], authentication: "public" }
  },

//import BasePrintTable from '@/components/base/BasePrintTable.vue'
  {
    path: '/dkhsawards',   name: 'dkhsawards',
    component: () => import(/* webpackChunkName: "awards" */ '@/views/awards/AwardStories.vue'),
    meta: {layout: la[1], authentication: "werner" }
  },
  {
    //award - edit
    path: '/studentawards',    name: 'studentawards',
    component: () => import(/* webpackChunkName: "awards" */ '@/views/awards/StudentAwardTable.vue'),
    meta: {layout: la[1], authentication: "werner" }
  },  {
    path: '/awardedit', name: 'awardedit',
    component: () => import(/* webpackChunkName: "awards" */ '@/views/awards/AwardEdit.vue'),
    meta: {layout: la[4], authentication: "werner" }
  },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/views/ViewSubjects.vue')
    ,name: 'ViewSubjects'
    ,path: '/subjects'
    ,meta: {layout: la[4], authentication: "admin"}
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/views/student/MainStudentClassList.vue'),
    path: '/studentlist',
    name: 'studentlist',
    props:true,
    meta: {layout: la[4], authentication: "admin" }
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/components/classlist/StudentClass.vue'),
    path: '/class/:gc?',
    name: 'ClassList',
    params: {title:"studentClassList",gc:{ g: "G12", c: "E1" }},
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/components/classlist/TeacherClassList.vue'),
    path: '/teacherlist',
    name: 'teacherlist',
    meta: {layout: la[4], authentication: "admin" },
  },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/components/classlist/TeacherClassEdit.vue'),
    path: '/tce/:listID?',
    name: 'TeacherClassEdit',
    props:true,
    meta: {layout: la[4], authentication: "admin" }
  },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/views/student/StudentInfo.vue'),
    path: '/student/:studentid?',
    name: 'StudentInfo',
    props:true,
    meta: {layout: la[4], authentication: "admin" }
  },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/components/student/StudentNotes.vue'),
    path: '/studentnotes/:studentid?',
    name: 'StudentNotes',
    props:true,
    meta: {layout: la[4], authentication: "admin" }
  },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/components/student/StudentParent.vue'),
    path: '/parent/:studentid?',
    name: 'ParentInfo',
    props:true,
    params:{studentid: 20001},
    meta: {layout: la[4], authentication: "admin" }
  },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/views/PersonelInfo.vue'),
    path: '/personel',
    name: 'PersonelInfo',
    meta: {layout: la[4], authentication: "public" }
  },
  // {
  //   component: () => import(/* webpackChunkName: "pers" */ '@/components/staff/PersonelNameCardDemo.vue'),
  //     path: '/personel/:surname?/:initial?/:user?',
  //     name: 'PersonelCard',
  //     props: true,
  //     params: {persName: 'what'},
  //     meta: {layout: la[4], authentication: "public" }
  // },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/components/staff/RealSignature.vue'),
      path: '/signature/:menemonic?/:userid?',
      name: 'Signature',
      props: true,
      params: {menemonic: 'what'},
      meta: {layout: la[4], authentication: "public" }
  },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/components/staff/EditSignature.vue'),
      path: '/editsignature/:menemonic?/:userid?',
      name: 'EditSignature',
      props: true,
      params: {menemonic: 'what'},
      meta: {layout: la[4], authentication: "public" }
  },
  {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/ViewFunctions.vue'),
    path: '/viewfunctions',    name: 'ViewFunctions',    meta: {layout: la[4], authentication: "admin" }
  },
  {
    component: () => import(/* webpackChunkName: "folder" */ '@/views/learn/Latest.vue')
    ,name: 'latest'
    ,path: '/latest/:days?'
    ,props: true,    params: {days: 7}
    ,meta: {layout: la[4], authentication: "student"}
  },
  {
    component: () => import(/* webpackChunkName: "about" */ '@/components/email/EmailBulletins.vue')
    ,name: 'newsletters'
    ,path: '/newsletters/:emailSearch?'
    ,props: true,    params: {emailSearch: 'd'}
    ,meta: {layout: la[0], authentication: "public"}
  },
  {
    component: () => import(/* webpackChunkName: "work" */ '@/views/LogCheck.vue')
    ,name: 'checklog'
    ,path: '/checklog'
    ,meta: {layout: la[4], authentication: "admin"}
  },
  {
    component: () => import(/* webpackChunkName: "email" */ '@/views/EmailCheck.vue')
    ,name: 'EmailCheck'
    ,path: '/emailcheck'
    ,meta: {layout: la[4], authentication: "admin"}
  },
  {
    component: () => import(/* webpackChunkName: "work" */ '@/views/Werner.vue')
    ,name: 'Werner'
    ,path: '/werner'
    ,meta: {layout: la[4], authentication: "admin"}
  },
  {
    component: () => import(/* webpackChunkName: "work" */ '@/views/test/WernerTest.vue')
    ,name: 'WernerTest'
    ,path: '/wernertest'
    ,meta: {layout: la[4], authentication: "werner"}
  },
  {
  component: () => import(/* webpackChunkName: "work" */ '@/views/test/PrintCard.vue')
  ,name: 'printCard'
  ,path: '/printcard'
  ,props:true
  ,meta: {layout: la[0], authentication: "werner"}
 },

  // {
  //   component: () => import(/* webpackChunkName: "work" */ '@/tmp/w/WernerTest.vue')
  //   ,name: 'WernerT'
  //   ,path: '/wernert'
  //   ,meta: {layout: la[4], authentication: "werner"}
  // },

  {
    component: () => import(/* webpackChunkName: "email" */ '@/components/email/EmailDeliveryReport.vue')
    ,name: 'EmailDeliveryReport'
    ,path: '/emaildeliver/:deliverid?'
    ,props:true
    ,meta: {layout: la[4], authentication: "teacher"}
  },
  {
    path: '/emailssent/:subid?',    name: 'emailssent',
    component: () => import(/* webpackChunkName: "email" */ '@/views/EmailsSent.vue'),
    props: true,    params: {subid: 6229, editmode: false},
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "vote" */ '@/views/vote/ViewCampaigns.vue')
    ,name: 'ViewCampaigns'
    ,path: '/elections'
    ,meta: {layout: la[4], authentication: "student"}
  },
{
    component: () => import(/* webpackChunkName: "vote" */ '@/views/vote/ViewVote.vue')
    ,name: 'ViewVote'
    ,path: '/candidates/:campaignid'
    ,params: {campaignid: false}
    ,props: true
    ,meta: {layout: la[4], authentication: "student"}
  },
  {
    component: () => import(/* webpackChunkName: "vote" */ '@/views/vote/ApplicantRegister.vue')
    ,name: 'ApplicantRegister'
    ,path: '/applicant/:campaignid'
    ,props: true
    ,meta: {layout: la[4], authentication: "student"}
  },
  {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/UserList.vue')
    ,name: 'UserList'
    ,path: '/userlist'
    ,meta: {layout: la[4], authentication: "teacher"}
  },
  {
    component: () => import(/* webpackChunkName: "test" */ '@/components/TextColorPicker.vue')
    ,name: 'color'
    ,path: '/color'
    ,meta: {layout: la[4], authentication: "public"}
  },

  {
    component: () => import(/* webpackChunkName: "home" */ '@/views/AutoRoute.vue'),
    path: '/ar',
    name: 'AutoRoute',
    meta: {layout: la[4], authentication: "public"}
  },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/views/IncomingPhotoLink.vue')
    ,name: 'IncomingPhotoLink'
    ,path: '/photohelp'
    ,meta: {layout: la[4], authentication: "public"}
  },
  {
    component: () => import(/* webpackChunkName: "about" */ '@/views/AllPhotos.vue')
    ,name: 'AllPhotos'
    ,path: '/photos/:switch?'
    ,meta: {layout: la[4], authentication: "teacher"}
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/views/Reinette.vue')
    ,name: 'eksamendruk'
    ,path: '/eksamendruk'
    ,meta: {layout: la[4], authentication: "admin"}
  },
  {
    path: '/wifi/:studentid?',    name: 'wifi',
    component: () => import(/* webpackChunkName: "pers" */ '@/components/student/StudentWiFi.vue'),
    props: true,
    params: {studentid: 17033, editmode: false},
    meta: {layout: la[4], authentication: "student" }
  },
  {
    path: '/att/:studentid?',    name: 'StudentAttendance',
    component: () => import(/* webpackChunkName: "atten" */ '@/components/student/StudentAttendance.vue'),
    props: true,
    params: {studentid: 17033, editmode: false},
    meta: {layout: la[4], authentication: "student" }
  },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/views/BookReturn.vue'),
    path: '/bookreturn',
    name: 'BookReturn',
    props:true,
    meta: {layout: la[4], authentication: "admin" }
  },
  {
    component: () => import(/* webpackChunkName: "deten" */ '@/components/detention/GradeClassHead.vue'),
    path: '/gch',
    name: 'GradeClassHead',
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "deten" */ '@/components/detention/DetStart.vue'),
    path: '/detstart',
    name: 'DetentionStart',
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "deten" */ '@/components/detention/DetTable.vue'),
    path: '/dettable',
    name: 'DetentionTable',
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "deten" */ '@/components/detention/PersonelDetList.vue'),
    path: '/persdet',
    name: 'PersonelDetList',
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "deten" */ '@/components/detention/DetentionPoints.vue'),
    path: '/studentpoints',
    name: 'DetentionPoints',
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "deten" */ '@/components/detention/ScheduleDetention.vue'),
    path: '/scheduledetention',
    name: 'ScheduleDetention',
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    path: '/meritadd/:meritid/:studentid?/:description?',
    name: 'MeritStepper',
    component: () => import(/* webpackChunkName: "deten" */ '@/components/merit/MeritStepper.vue'),
    props: true,
    params: {meritid: 1,studentid:'', description:''},
    meta: {layout: la[4], authentication: "student" }
  },
  {
    path: '/merittable',
    name: 'MeritTable',
    component: () => import(/* webpackChunkName: "deten" */ '@/components/merit/MeritTable.vue'),
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "deten" */ '@/components/merit/MeritStart.vue'),
    path: '/meritstart',
    name: 'MeritStart',
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "pers" */ '@/components/staff/PersonelMeritList.vue'),
    path: '/persmeritlist',
    name: 'PersMeritList',
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "deten" */ '@/components/merit/MeritForm.vue'),
    path: '/meritedit/:id',
    name: 'MeritForm',
    props:true,
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "test" */ '@/views/HelloWorld.vue'),
    path: '/helloworld/:menemonic?',
    name: 'HelloWorld',
    props:true,
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    path: '/attendance/:date?',    name: 'AttendanceView',
    component: () => import(/* webpackChunkName: "atten" */ '@/views/AttendanceViewOld.vue'),
    props: true,
    params: {date: '', editmode: false},
    meta: {layout: la[4], authentication: "admin" }
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/views/Attendance4.vue'),
    path: '/attload',
    name: 'Attendance4',
    props:true,
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/components/attendance/StudentAttendanceList.vue'),
    path: '/attendanceadd',
    name: 'AttendanceList',
    props:true,
    params: {studentList: [],checkList:['yes','no'],attendanceDetail: {staffSurname:'', location:'', period:'', }},
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/views/StartGenList.vue')
    ,name: 'StartGenList'
    ,path: '/vglist'
    ,meta: {layout: la[4], authentication: "admin"}
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/components/attendance/StudentGeneralList.vue'),
    path: '/generaladd',
    name: 'GeneralList',
    props:true,
    params: {studentList: [],checkList:['yes','no'],listDetail: {staffSurname:'', location:'', period:'', }},
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/components/attendance/StudentGenListView.vue'),
    path: '/genlistview',
    name: 'GeneralListView',
    props:true,
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/components/attendance/StudentGenListSession.vue'),
    path: '/genlistsession/:sessionid?',
    name: 'GenListSession',
    props:true,
    params: {sessionid:0},
    meta: {layout: la[4], authentication: "teacher" }
  },
  // {
  //   component: () => import(/* webpackChunkName: "atten" */ '@/components/homework/ViewGeneralList.vue'),
  //   path: '/gtlist',
  //   name: 'ViewGeneralList',
  //   props:true,
  //   params: {listid: 0},
  //   meta: {layout: la[4], authentication: "teacher" }
  // },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/components/attendance/StudentAttendanceView.vue'),
    path: '/attview',
    name: 'AttendanceView4',
    props:true,
    params: {},
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "atten" */ '@/components/attendance/StudentAttendanceSession.vue'),
    path: '/attsession/:sessionid?',
    name: 'AttendanceSession',
    props:true,
    //params: {sessionid:0},
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "test" */ '@/views/JdocViewEdit.vue'),
    path: '/jdoc',
    name: 'JdocViewEdit',
    meta: {layout: la[4], authentication: "teacher" }
  },

  {
    component: () => import(/* webpackChunkName: "reports" */ '@/views/Reports.vue'),
    path: '/reports',
    name: 'Reports',
    props:true,
    meta: {layout: la[4], authentication: "teacher" }
  },
  {
    component: () => import(/* webpackChunkName: "test" */ '@/components/vue3/User.vue')
    ,name: 'test3'
    ,path: '/test3'
    ,meta: {layout: la[4], authentication: "public"}
  },
  {
    component: () => import(/* webpackChunkName: "workl" */ '@/api/csv/FrontJsonToCsvFunction.vue')
    ,name: 'FrontJsonToCsvFunction'
    ,path: '/export/:route?'
    ,props: true
    ,params: ["route"]
    ,meta: {layout: la[4], authentication: "teacher"}
  },
  {
    component: () => import(/* webpackChunkName: "workl" */ '@/api/csv/JsonToCsv.vue')
    ,name: 'JsonToCsv'
    ,path: '/tocsv/:route'
    ,props: true
    ,meta: {layout: la[4], authentication: "teacher"}
  },
  {
    component: () => import(/* webpackChunkName: "test" */ '@/views/ErrorPage.vue')
    ,name: 'ErrorPage'
    ,path: '*'
    ,meta: {layout: la[4], authentication: "public"}
  },
]

import { publicPath } from '../../vue.config'
import { ls } from '@/api/localStorage.js'
const router = new VueRouter({
  mode: 'history',
  base: publicPath, //'virtual-school',    //This works : /zmltest/  but ./ does not work for layouts
  werner: 'werner',      //see if I can add my own stuff.
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {      return savedPosition
  //   } else {      return { top: 0,  behavior: 'smooth' }   }
  // },
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    console.log('ind:ScrollBehavior(',to, from, savedPosition,')' )
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 500)
    })
  },

})

router.beforeEach((to, from,next) => {
  cl('stalecheck-version','router index.js')
  if (!staleCheck.checkCurrentVersion()) {
    staleCheck.resetLocalStorageData()
  }
  if (ls.test('zmllogin') && getters.getState({ object: "gZml" }).login.type == 'guest' ) {
    getters.getState({ object: "gZml" }).login = ls.load('zmllogin')
    if (ls.test('zmlPersM')) {
      getters.getState({ object: "gZml" }).persMenemonic = ls.load('zmlPersM')
    }
  }
  const userAuth = getters.getState({ object: "gZml" }).login.isAuthenticated
  const userType = getters.getState({ object: "gZml" }).login.type

  // cl('R - From.name, to.name |', from.name,'|', to.name,'|', from.path,'|', to.path);

  zmlLog(getters.getState({ object: "gZml" }).login.username
        ,"Route"
        , `${from.name}->${to.name} : ${from.path} -> ${to.path}`)

  if (to.name == from.name) {
    if (to.params && to.params == from.params){
    //do nothing
    } else {
      next();
    }
  } else {
    cl('R - Auth Meta:', to.meta.authentication,userAuth, userType);
    if (to.meta.authentication != 'public' && userAuth == false && to.name != 'Login') {
      cl('R - ForceLogin')
      next({name: 'Login', meta:{message:'Bad Authentication for ' + to.name}})
    } else if (to.meta.authentication == 'teacher' && userType == 'student' && to.name != 'Login') {
      cl('R - ForceLogin - student not allowed')
      next({name: 'Login'
          , props: { errorMessage: 'there was an error' }
          , meta:{message:'Bad Authentication for ' + to.name}})
    }
    else if (to.meta.authentication == 'admin' && userType == 'student' && to.name != 'Login') {
      cl('R - ForceLogin - student not allowed')
      next({name: 'Login'
          , props: { errorMessage: 'there was an error' }
          , meta:{message:'Bad Authentication for ' + to.name}})
    }
    else {
      cl('R - Just Next', to)
      next()
      addRouteToListForZmlNavMenu(to)
    }
    /*
    if (to.name == 'Home' ) {
       if (getters.getState({ object:"gZml" }).login.isAuthenticated == true) {
            //cl('Logged in : take hime to other home')
            next({name: 'About'})
        } else {
          next();
        }
    } else {
      */
  }
});

function addRouteToListForZmlNavMenu(route) {
   let r = getters.getState({ object: "gZml" }).routes
   cl('addRouteToListForZmlNavMenu', route)
   r.push(route)

}

export default router
