<template>
   <v-text-field class="pt-2 xmt-2"
                 single-line hide-details
                :clearable="clearable"
                :outlined="outlined"
                 placeholder="search"
                 append-icon="mdi-magnify"
                :value="value"
                @input="updateValue"
   >
   </v-text-field>
</template>

<script>
export default {
  name: "baseSearch",
 props: {
      value: {
        type: String,
        default: ''
      },
      outlined:{type:Boolean, default:true},
      clearable:{type:Boolean, default:true},
    },
  methods: {
      updateValue: function (event) {
        this.$emit('input', event)
      }
  }
}
</script>
