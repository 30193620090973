export const en = {
    btn: {
        home: "Home",
        tiphome: "Home",
        pers: "Personel",
        tippers: 'Persons, Staff, Teachers',
        kal: "Calendar",
        tipkal: "School Schedule",
        Educational_Resources: "Educational Resources",
        tipEducational_Resources: "A bit like google drive",
        admin: "Admin",
        tipadmin: "All things Admin",
        about : "About",
        tipabout : "More about about",
        studentfiles: "Learn",
        tipstudentfiles: "Student Files",
        viewcats: "Student",
        tipviewcats: "View Categories",
        teacher: "Teacher",
        tipteacher: "Change data",
        translate : "Translate",
        tiptranslate : "test some trasnlation functions",
        help : "Help",
        tiphelp : "More about help",
        reports : "Reports",
        tipreports : "Various Report",
        attendance : "Attendance",
        tipattendance: "Class Attendance for Teachers",
        changeTo : "Change To - Afr",
        tipchangeTo : "Click here to change to Afrikaans",
        rightmenubutton: "menu",
        Login: "Login",
        tipLogin: "Tip for Login",
        Profile: "Profile",
        tipProfile: "Tip for Profile",
        Logout: "Logout",
        tipLogout: "Tip for Logout",
        modal: "toetsModal",
        tipmodal: "toets gou nested .. Modal",
        testsConfirm: "confirm",
        tiptestsConfirm: "test our DialogConformModel",
    },
    message: {
      programname: "DK Online Resources",
      titleButtonHeading: "DK Online Resources Shortcuts",
      titleButtonHeadingCloseButton: "Close Shortcuts",
      herotitle: " De Kuilen Online Resources - Hopefully zero rated in 2021",
      SubmityourEmail: "Submit the Email",
      LoginHeading: "Login",
      hello: 'hello world',
      greeting: (ctx) => `hello, ${ctx.named('name')}!`,
      leeting: (x) => `hello (name), ${x.list(0)}!`,
      nested:{
          binnein: "this is inside",
      },
    donner: 'oh no!',
    Delete:"Delete",
    AreYouSure:"Are you sure?",
    },
      computed: {
        nickName () { return 'Werner' }
    },
    data: { greet: 'hello' }
}

export const af = {
    btn: {
        home: "Huis",
        Educational_Resources: "Hulpbronne",
        tipEducational_Resources: "Amper soos google drive",
        tiphome: "Huis",
        admin: "Admin",
        tipadmin: "All things Admin",
        tipabout : "Meer oor ons",
        pers: "Personeel",
        kal: "Kalender",
        tipkal: "Skool Skedule",
        tippers: 'Persone, Onnies, Hulp',
        studentfiles: "Leer",
        tipstudentfiles: "Studente se Leers",
        teacher: "Onnies",
        tipteacher: "Verander data",
        translate : "Vertaal",
        tiptranslate : "Toets vertaal funksies",
        help : "Hulp",
        tiphelp : "Meer oor help",
        reports : "Verslae",
        tipreports : "Verskeie Verslae",
        attendance : "Bywoning",
        tipattendance: "Klas Bywoning",
        changeTo : "Verander na - Engels",
        tipchangeTo : "Kliek hier om alles in engels te sien",
        rightmenubutton: "kieslys",
        Login: "Teken Aam",
        tipLogin: "Tip for Login",
        Profile: "Profiel",
        tipProfile: "Tip for Profile",
        Logout: "Teken Uit",
        tipLogout: "Tip for Uitteken",
        modal: "toestModal",
        tipmodal: "toets gou nested .. Modal",
        testsConfirm: "maakseker",
        tiptestsConfirm: "toets ons DialogConfirmModel",
    },
    message: {
        programname: "DK Aanlyn Hulpbronne",
        titleButtonHeading: "xxDK Hulpbron Kortpaaie",
        titleButtonHeadingCloseButton: "Maak Toe",
        herotitle: " De Kuilen AANLYN HULPBRON",
        SubmityourEmail: "Stuur die brief",
        LoginHeading: "Meld Aan",
        hello: 'hallo wereld',
        greeting: (ctx) => `dagsê, ${ctx.named('name')}!`,
        leeting: (x) => `dagsê (naam), ${x.list(0)}!`,
         nested:{
          binnein: "hirsie is binnein",
        },
        donner: 'agge nee!',
        Delete:"Vee Uit",
        AreYouSure:"Is jy seker?",
      },
    computed: {
        nickName () { return 'Werner' },
    },
      data: { greet: 'hello' },
}