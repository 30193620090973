<template>
 <span>
  <v-img v-if="name == 'DKBadge40'" src="img/CleanDKHS.png" max-width="40" contain :height="height || 40">
    <slot />
  </v-img>
  <v-icon v-if="name == 'Hammie'">mdi-hamburger</v-icon>
  <v-icon v-if="name == 'menu'">mdi-menu</v-icon>
  <span v-if="name == 'username'"> {{ getZml.login.username }}</span>
  <v-img v-if="name == 'studentphoto'" class="rounded"
              :src="'https://kuiliesonline.co.za/api/candid/candidates.php?task=photo&studentno=' + id"
              :height="height || 100" :title="id || 'no id passed'" contain >
      <slot />
  </v-img>

  <v-img v-show="name == 'persphoto'" class="rounded"
              :src="'https://kuiliesonline.co.za/api/candid/candidates.php?task=photo&pers=' + id"
              :height="height || 50" :title="id || 'no id passed'" contain >
      <slot />
  </v-img>
</span>
</template>

<script>
import { getters } from "@/api/store"
export default {
  name: 'ZShow',
  props:['name','id','height'],
  components:{},
  data: () => ({
      getZml: getters.getState({ object: "gZml" }),
  }),
  mounted() {}
}

</script>

<style scoped>
.rounded{
    border-radius:50px;
}