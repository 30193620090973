<template>
  <v-app id="app" >
   <app-layout :key="projectID" >
   <v-container fluid class="ml-sm-0 mr-sm-0 mr-md-2 ml-md-2  mt-2 mt-md-2 pa-0">
      <!-- <v-scroll-x-transition mode="in" hide-on-leave="true">    -->
     <!-- <v-fade-transition mode="in" xhide-on-leave="true"> -->
      <transition name="slide-fade" hide-on-leave="true">
      <!-- <transition name="slide-x-transition" hide-on-leave="true"> -->
       <keep-alive :max="10">
        <router-view />
      </keep-alive>
      </transition>
   </v-container>
   </app-layout>

    <v-snackbar
      class="no-print"
      top centered
      :color="snackbarColor"
      elevation="21"
      v-model="snackbar"
      timeout="1800">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="indigo" text
          v-bind="attrs"  @click="snackbar = false"
        >Close</v-btn>
      </template>
    </v-snackbar>
    <confirm ref="confirm" class="no-print"></confirm>
  </v-app>
</template>


<script>
import { getters } from "@/api/store"
import { zmlConfig } from '@/api/constants'
import { zData } from "@/api/zGetBackgroundData.js"
import confirm from "@/api/DialogConfirm"
import EventBus, { ACTIONS } from '@/api/event-bus'
import { staleCheck } from "@/api/localStorage.js"
//import { cookie } from "@/api/localStorage.js"
function cl(...args) {
    console.log('App.vue',...args)
}


//https://stackoverflow.com/questions/71168747/how-to-make-vuetify-dialog-draggable - make dialog windows (v-dialog) draggable
(function () { // make vuetify dialogs movable
    const d = {};
    document.addEventListener("mousedown", e => {
        const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
        if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-card__title")) { // element which can be used to move element
            d.el = closestDialog; // element which should be moved
            d.mouseStartX = e.clientX;
            d.mouseStartY = e.clientY;
            d.elStartX = d.el.getBoundingClientRect().left;
            d.elStartY = d.el.getBoundingClientRect().top;
            d.el.style.position = "fixed";
            d.el.style.margin = 0;
            d.oldTransition = d.el.style.transition;
            d.el.style.transition = "none"
        }
    });
    document.addEventListener("mousemove", e => {
        if (d.el === undefined) return;
        d.el.style.left = Math.min(
            Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
            window.innerWidth - d.el.getBoundingClientRect().width
        ) + "px";
        d.el.style.top = Math.min(
            Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
            window.innerHeight - d.el.getBoundingClientRect().height
        ) + "px";
    });
    document.addEventListener("mouseup", () => {
        if (d.el === undefined) return;
        d.el.style.transition = d.oldTransition;
        d.el = undefined
    });
    setInterval(() => { // prevent out of bounds
        const dialog = document.querySelector(".v-dialog.v-dialog--active");
        if (dialog === null) return;
        dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
        dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
    }, 100);
})();


export default {
  name: 'ZmlApp',
  components: {confirm},
  data: () => ({
    getZml: getters.getState({ object: "gZml" }),
    snackbarMessage: "",
    snackbarColor: "red accent-4",
    snackbar: false,
    projectID: zmlConfig.projectID,
    dinges:{}
  }),
  methods: {
    doSomethingAfterSuccessOnLoad() {
      this.$super.user = this.getZml.login.superUser || false    //True or false
      this.$super.username = this.getZml.login.username
      this.$super.fullname = this.getZml.login.fullname
      this.$super.userid  = this.getZml.login.userid
      this.$super.isAuthenticated = this.getZml.login.isAuthenticated
      cl('We are at last finished with loading all the data')
      //Cheat way to save user to windows...
      window.$aa = this.getZml.login
      this.$super.test =  (new Date().getTime() - this.$super.test) / 1000 + ' seconds'


      //Seconds between two dates
      //startDate = new Date();
      // Do your operations
      //endDate   = new Date();
      // seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    }
  },
  mounted() {
   cl('StartApp : ',this.projectID)

   this.$super.test = new Date().getTime()
   // Quick fetch of data, if all is good - otherwise a longer fetch..
   // Check if a reload is needed (if the version number changed) or data is stale
     cl('Version and stale check')
     if (staleCheck.checkIsDataStale()) {
      cl('ls deleted - expect login  - we will reload')
     } else {
      cl('All is good - carry on from localStorage')
     }

     cl('loadInitData')
     zData.quickLoadInitialData('App.Vue: Load LocalStorage', this.doSomethingAfterSuccessOnLoad)
     //All data is now loaded, lets use a shortcut to store some static data..




/* START External Programs that uses app.vue to make use of global stuff.    */
    this.$root.$confirm = this.$refs.confirm.open
    EventBus.$on(ACTIONS.SNACKBAR, (message, color) => {
        this.snackbarMessage = message;
        if (color) {
           this.snackbarColor = color
        }
        this.snackbar = true;
      });
/* END External Programs that uses app.vue to make use of global stuff.    */
  },
  destroyed () {
    EventBus.$off(ACTIONS.SNACKBAR)
  }
};
</script>

<style>
@media print {
.noprint {
    display:none;
  }
}
.hide {display:none;}
</style>

<style scoped>
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(30px);
  opacity: 0;
}

.slide-x-transition-enter-active {
  background-color: #b2fab2;
  transition: background-color 2s, all 2s;
}
.slide-x-transition-enter-to {
  background-color: white;
}
.slide-x-transition-leave-active {
  background-color: white;
  transition: background-color 2s, all 2s;
}
.slide-x-transition-leave-to {
  background-color: #fccece;
}
</style>
