<template>
  <v-dialog v-model="showAlways" max-width="720" :fullscreen="$vuetify.breakpoint.smAndDown">
    <Signature2 v-if="ready" :pers="pers" :backdoor="backdoor"/>
  </v-dialog>
</template>


<script>
import { getters } from "@/api/store";
import { zmlFetch } from '@/api/zmlFetch';
import Signature2 from "@/components/staff/Signature2.vue"
function cl(...args) {    console.log('RSIG',...args) }
export default {
    name:"RealSignature",
    props: ['userid','menemonic'],
    components:{
        Signature2
    },
    data: () => ({
        showAlways:true,
        persMenemonic: getters.getState({ object: "gZml" }).persMenemonic,
        signatureWidth: 610,
        socialIcons: 'icons',
        lastLine: '',
        leftSideOfSocialIconLine: '.',
        pers: {menemonic:'', userid:0, role:'', workarea:'', items:[]},
        photoStuff: {data:{forPic:'forpictre'}},
        backdoor: 0,
        ready: 0
    }),
    methods: {
      async fetchFull() {
        this.pers = {}
        this.pers.items = []
        if (this.menemonic) {
          //zmlFetch({task: 'plainSql', sql:'select * from dkhs_personel where persid = ' + this.cPersName.persid}, this.afterFetch);
          await zmlFetch({task: 'plainSql'
                   , sql:`select * from dkhs_personel where menemonic = '${this.menemonic}'`}
                   , this.afterFetch);
        }
      },
      async afterFetch(response) {
        this.pers = response[0]
        this.backdoor++;
        await zmlFetch({task: 'plainSql', sql:`select * from dkhs_signature where length(value) > 1 and  username = '${this.menemonic}' or username = '${this.persid}'`}, this.afterFetch2);
      },
      afterFetch2(res) {
        this.pers.items = []
        res.forEach(e => {
          if (e.value == '') return
          if (e.name == 'role') {this.pers.workarea = e.value; return}
          if (e.name == 'name') {this.pers.public_preferredname = e.value; return}
          this.pers.items.push(e)
        })

        this.backdoor++;
        this.ready++;
      },
    },
    async mounted() {
        cl('start', this.$route.params)
        if (this.menemonic) {
          await this.fetchFull()
          return
       }

    },
    watch: {
         showAlways() {
          cl('ShowAlways changed.')
          this.$emit('close', this.showAlways)
        //   this.$router.back()
         },
        userid() {
            this.fetchFull()
        },
        menemonic() {
            this.fetchFull()
        }
    }
}
</script>