<template>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn xicon v-bind="attrs" v-on="on" @click="$router.back()" :title="backtitle || 'Go Back'">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-menu>
</template>

<script>
export default {
  name:"VBack",
  props:{
    backtitle: {type:String, default:"go back"}
  }
}
</script>