import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
//import './assets/css/werner.css'; //(We r trying import it form public at runtime)

//https://html2canvas.hertzen.com/
// used in fundraiser.vue
// eslint-disable-next-line

// import VueHtml2Canvas from 'vue-html2canvas'
// import "regenerator-runtime/runtime"
// Vue.use(VueHtml2Canvas)

 import wernerPlugin from './plugins/wernerPlugin.js'
 Vue.use(wernerPlugin,{hello:'from app mainjs'})

//Components are defined in our wernerPlugins
//Vue.component('AppLayout', resolve => { require(['@/layouts/AppLayout'], resolve) })
//Vue.component('zSpan', resolve => { require(['@/components/global/zSpan.vue'], resolve) })

Vue.config.productionTip = false

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

window.onerror = function(message, url, lineNumber) {
  window.location.reload()
  //alert('OnErr:' + message + lineNumber + url)
  window.location.reload()
  return true;
};

// eslint-disable-next-line
//Vue.prototype.$superUser = false;  //we will attempt to link this when we logged in
//Vue.prototype.$test = "This is a test message"  //we will attempt to link this when we logged in

// How to add components globally so all can see them - testing with small one
Vue.component('v-back', resolve => { require(['@/components/base/VBack.vue'], resolve) })
Vue.component('v-close', resolve => { require(['@/components/base/VClose.vue'], resolve) })

import { af,en } from '@/api/translate'
const messages = {
  en,
  af
}
let i18n = new VueI18n({
  locale: 'af',
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  messages: messages
})

// Vue.config.errorHandler = (err) => {
//   console.error('vue.config.errorhandle',err);
// };

Vue.use(vuetify);
new Vue({
  i18n,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
