<template>
 <!-- https://blog.logrocket.com/guide-css-word-wrap-overflow-wrap-word-break/ -->
 <v-card xmax-width="500" class="mx-auto" :color="color" elevation="2" style="word-wrap: break-word;overflow-wrap: break-word;">
   <v-card-title class="headline ma-1" > {{ heading }} </v-card-title>
   <v-card-text v-if="emailList">
   <v-data-table
         :headers="emailHeader"
         :items="emailList"
         :items-per-page="15"
         :hide-default-footer="true"
         class="elevation-1"
         @click:row="clickOnRow"
         mobile-breakpoint="0"
       ></v-data-table>
   </v-card-text>
   <v-card-text v-else>
      Oops - no emails found for {{ studentid }}
   </v-card-text>
 </v-card>
</template>
<script>
import { zmlFetch } from "@/api/zmlFetch";
import { getters } from "@/api/store";
export default {
    name:"StudentEmailList",
    props: {
      studentid:{default:null}
     ,color: {default:'blue'}
     ,heading: {default:'EBulletin Emails'}
      },
    data: () => ({
      getZml: getters.getState({ object: "gZml" }) ,
      emailList:null,
      emailHeader: [
        //{text: 'id',       align: 'start',  value: 'subid' },
          {text: 'email',    align: 'start',  value: 'email' },
        //{text: 'impNumber',align: 'start',  value: 'impnumber' },
        //{text: 'fullname', align: 'start',  value: 'name' },
        //{text: 'Note',     align: 'start',  value: 'extra' },
          {text: 'Group',    align: 'start',  value: 'grpname' },
          {text: 'Inserted', align: 'start',  value: 'insertdate' },
          {text: 'Changed',  align: 'start',  value: 'changedate' },
          {text: 'OptOut',   align: 'start',  value: 'description' },
        //{text: 'SubID',    align: 'start',  value: 'subid' },
        ]
    }),
    methods:{
      clickOnRow(e) {
        console.log('emails clicked on row',e)
        if (['admin','teacher'].includes(this.getZml.login.type)) {
           this.$router.push({ name: 'emailssent', params: {subid: e.subid, editmode: false} })
        } else {
            alert('You are not allowed to edit')
        }
      },
      getEmails() {
        if (this.studentid) {
           let sl = { task: 'plainSql'
                    , sql: `SELECT s.email, m.description, impnumber, insertdate, changedate, extra, s.subid, IFNULL(group_concat(grpname), 'NONE') grpname\
                              FROM m_subscriber s\
                            left join m_out m on s.outid = m.outid\
                            left join m_grouplink g on s.subid = g.subid\
                            left join m_group a on a.grpid = g.grpid\
                             WHERE impnumber = ${this.studentid}\
                            GROUP BY s.email, m.description, impnumber, insertdate\
  union all select l.user_email, 'student', user_name, firstlogindate, lastlogindate, user_fullname, l.userid, 'learner'\
  FROM dkhs_learner l WHERE user_name = '${this.studentid}'`}
           zmlFetch(sl, this.processAfterFetch);
        }
      },
      processAfterFetch(response) {
        console.log(response)
          if (!response.error) {
             this.emailList= response
          } else {
              this.emailList = []
          }
      }

    },
    mounted: function() {
        console.log(this.$options.name,  this.studentid)
        if (this.studentid) {
           this.getEmails()
        }
    },
    watch: {
        studentid(n,o) {
            if (n != o) this.getEmails()
        }
    }
}
</script>