import { zmlConfig } from '@/api/constants';
import Vue from 'vue'
/*-----------------------------
zmlFetch - to make my code more readable.
You can pass only first param if there are no callback
self can be used with let self=this, and then you pass all you data here.
will make this function a bit bloated.
See https://developers.google.com/web/ilt/pwa/working-with-the-fetch-api if you lose track.
-----------------------------*/

function cl(...args) {
  //console.log('FET',...args)
}

// eslint-disable-next-line
function zmlFetch(task,callback,errcallback, extraParameter) {
        task.program = 'zmlShop'
        task.status = 'trying'
        task.super = Vue.prototype.$super || 'We have no super'
        let apiConfig = {method: 'POST',
                  headers: {'Accept': 'application/json'
                         , 'Content-Type': 'application/json;charset=UTF-8'},
                  body: JSON.stringify(task)}
        cl('FETCH-------------- : ', task.task, task)

        fetch(task.api ? task.api : zmlConfig.apiPath, apiConfig)
        .then(response => {
            if (response.ok && (response.status >= 200 && response.status <= 299)) {
              return response.json();
            } else {
                cl('Throw response not ok error ',task.task,response.statusText)
                throw Error(response.statusText)
            }
        })
        .then(responseAsJson => {
           //here we can decompress if return is gzipped, or we can do local callback to save globals?
           cl('after fetch callback for ',task.task)
           if (callback) callback(responseAsJson,task,extraParameter ?? 'none')
           //Warning , you cannot return from a callback
        })
        .catch(err => {
            if ( typeof errcallback === 'undefined') {
                //('we have no error callback on this call', err, task.task)
                cl('catch with no callback',task.task,err)
            } else {
                cl('catch with errcallback',task.task,err)
                errcallback(err)
            }
        });
    }

//https://www.codepanion.com/posts/2020-02-02-how-to-use-async-await-promises-with-fetch-in-vue-js-vuex/
// eslint-disable-next-line
/*
async function http(url,
    method = 'GET',
    data,
) {
    try {
      const response = await fetch(url, {
        method,
        data
      });

      return await response.json();
    } catch (error) {
      throw error;
    }
}
*/

//Return a promise - zFetch
function zFetch(task) {
    cl('Z:FETCH-1--------------',task.task)
    task.program = 'zmlShop'
    task.status = 'trying'
    const apiConfig = {method: 'POST',
        headers: {'Accept': 'application/json'
                 ,'Content-Type': 'application/json;charset=UTF-8'},
        body: JSON.stringify(task)
        }
    return fetch(task.api ? task.api : zmlConfig.apiDKHS, apiConfig)
 }


//Return data - still have trouble with this
//https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
async function pFetch(task) {
    task.program = 'zmlShop'
    task.status = 'trying'

    // if we are calling with empty task, presume we are testing online..
    if (!task.api) task.api = zmlConfig.apiPath
    if (!task.task) task.task = 'online'

    const apiConfig = {method: 'POST',
        headers: {'Accept': 'application/json'
                 ,'Content-Type': 'application/json;charset=UTF-8'},
        body: JSON.stringify(task)
    }
    const response = await fetch(task.api ? task.api : zmlConfig.apiPath, apiConfig)
    const w = await response.json()
    console.log('pfetch wat is in w?', w)
    return w

    //NBNBNB - There is no error catching in here - when I ise try and catch, no values go back
}


async function zOneValue(sql) {
    function cl(...args) {
     console.log('zOV',...args)
    }
          cl('1', sql)
          let ts = { task: 'OneValueSql'
                   , api: zmlConfig.apiPath
                   , sql: sql
                   , answer:''}
          const apiConfig = {
              method: 'POST',
              headers: {'Accept': 'application/json' //"text/xml"
                       ,'Content-Type': 'application/json;charset=UTF-8'},
              body: JSON.stringify(ts)
          }
          cl('2:', apiConfig)
          await fetch(ts.api, apiConfig)
          .then((response) => {
            cl("response.type =", response.type);
            cl("response.url =", response.url);
            cl("response.userFinalURL =", response.useFinalURL);
            cl("response.status =", response.status);
            cl("response.ok =", response.ok);
            cl("response.statusText =", response.statusText);
            cl("response.headers =", response.headers);
            if (!response.ok) {
              throw new Error(`HTTP error, status = ${response.status}`);
            }
            if(response.headers.get("content-type") && response.headers.get("content-type").toLowerCase().indexOf("application/json") >= 0) {
              cl('we got json back')
              return response.json()
            } else {
              cl('we got ?? back',  response.headers.get("content-type"))
              return response.text()//response.headers.get("content-type"); //response.text();
            }

          })
          .then((text) => {
              cl('4:', text)
              ts.answer = text;
          })
          .catch((error) => {
            cl('E, Error:', error.message, JSON.stringify(error))
          });
          cl('5:', ts.answer)
          return ts.answer
  }

export {pFetch, zFetch, zmlFetch, zOneValue};
