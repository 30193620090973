<template>
   <v-container fluid id="hero-alt" v-if="!$vuetify.breakpoint.smAndDown" class="ma-0 pa-0 pb-2">
     <v-sheet max-width="100%"
              :color="color"
              justify="space-around"
              class="text-center ma-0 pa-0" align="center">
       <v-img
        :xxxheight="$vuetify.breakpoint.mdAndUp ? breakup1 : breakup2"
        :max-height="150"
        :gradient="gradient"
        :src="picture"
        :color="color"
        flat
        max-width="100%"
        tile
        :cover="true"
      >

        <v-row v-if="herotitle" align="center"
          class="ma-0 fill-height text-center white--text font-weight-black"
          justify="center"
        >
          <v-col cols="12">
            <div :title="herotitle" space="2" weight="500" class="text-h2">
             {{ herotitle }}
            </div>
            <hr :color="color" dense />
            <div class="justify-center pa-0 grey--text" divider=">" > </div>

            <v-card v-if="text" xxxxalign="center" class="ma-8 fill-height text-justify darkgray--text font-weight-light wordbreak" color="transparent">
             <p  v-if="text"> {{ text}} </p>
             <p  v-if="moreText"> {{ moreText}} </p>
              <v-btn v-if="herobutton"
                  :to="func"
                  :title="herotext">
              <v-icon> {{icon}}</v-icon>
              {{ herobutton }}
             </v-btn>
            </v-card>
         </v-col>
        </v-row>
      </v-img>
    </v-sheet>
  </v-container>
</template>

<script>
  // Components
  import { HexToRGBA, RGBAtoCSS } from 'vuetify/lib/util/colorUtils'


  export default {
    name: 'SectionHeroAlt',
    props: { name: String
           , bgpicture: String
           , title: {type: String, default: "A Title"}
           , text: String
           , moreText:String
           , button:String
           , icon:String
           , func:String
           , color: String
           , breakup1: {type: String, default: "100"}
           , breakup2: {type: String, default: "55"}
           },
    provide: {
      heading: { align: 'center' },
    },

    data: () => ({

    }),

    computed: {
      gradient () {
        const color = `${this.$vuetify.theme.themes.light.secondary}CC`
        const overlay = RGBAtoCSS(HexToRGBA(color))
        ////this.$cs.l('gradien:' , `to top, ${overlay}, ${overlay}`)
        return `to top, ${overlay}, ${overlay}`
      },
      picture () {
        return this.bgpicture ? this.bgpicture : "https://www.zmlrekenaars.co.za/test/img/wall001.jpg"
      },
      herotitle () {
       return this.title ? this.title   : "heroTitle for section"
      },
      herotext () {
       return this.text ? this.text : ""
      },
      herobutton () {
       return this.button
      },
    },
  }
</script>


<style scoped>

.slide-fade-enter-active {
  transition: all 2s ease;
}
</style>