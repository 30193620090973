<template>
  <v-container fluid v-if="getZml.login.isAuthenticated">
    <z-span> super:homeAdmin,  menusearch={{ menuSearch }}</z-span>

    <base-tool :toolList="toolList"
           toolbarName="Menu for Admin & Teachers"
           :loading="loading"
           :background="false"
           :back="true"
           @toolClick="catchToolbarClick">
    <template #onlynav>
      <v-card>Filter On
        <v-radio-group v-if="!$vuetify.breakpoint.smAndDown"
                v-model="menuType"
                dense class="mt-4" color="primary">
          <v-radio value="all" label="All" color="primary" />
          <v-radio value="admin" label="Admin"/>
          <v-radio value="other" label="Oth"/>
          <v-radio value="student" label="Stud"/>
          <v-radio value="teacher" label="Teach"/>
        </v-radio-group>
      </v-card>
    </template>
    <template #menu>
      <!-- As daar meer plek is, maak dit makliker -->
      <v-radio-group v-if="$vuetify.breakpoint.xlAndUp"
                v-model="menuType" outlined filled
                row dense class="mt-1" dark>
          <v-radio value="all" label="All"/>
          <v-radio value="admin" label="Admin"/>
          <v-radio value="other" label="Oth"/>
          <v-radio value="student" label="Stud"/>
          <v-radio value="teacher" label="Teach"/>
      </v-radio-group>
        <!-- <v-btn icon class="ma-2"
         title="Click here to refresh"
         @click="loadFunctions">
          <v-icon> mdi-refresh </v-icon>
        </v-btn> -->
      </template>
  </base-tool>

  <menu-list-new :info="menuInfo" :small="menuSmall" :type="menuType" :functionSearch="menuSearch" :title="menuType"/>

  <!-- <most-used-functions /> -->

</v-container>
</template>

<script>
import { getters } from "@/api/store";
import { zData } from "@/api/zGetBackgroundData.js"
import MenuListNew from '@/components/MenuListNew.vue';
import baseButtonDialog from "@/components/base/baseButtonDialog.vue"
import baseTool from '@/components/base/BaseTool.vue'
import { btw } from '@/components/base/baseToolWorker'
import BaseToolButton from '@/components/base/BaseToolButton.vue'
// import MostUsedFunctions from '@/components/MostUsedFunctions.vue'
function cl(...args) {    console.log('DF',...args) }

export default {
    name:"HomeAdmin",
    components:{
             //  Calendar
             //, PersonelMenemonic
             //, BaseTitleExpand
               baseButtonDialog
             , BaseToolButton
             , MenuListNew
             , baseTool
             //, MostUsedFunctions
    },
    data: () => ({

        getZml: getters.getState({ object: "gZml" }),
        wieOmTeWys:'Teacher',
        showCal:false,
        cards: ['Today', 'Yesterday'],
        today: new Date(),
        tomorrow: new Date(),
        schoolday: null,
        joke:'no joke',
        vtabs: '1',
        loading:false,
        menuInfo: false,
        menuSmall: false,
        menuType: 'teacher',
        menuSearch:false,
        toolList: [
          {id:0, name: 'Access',icon:'mdi-key', task:'action', func: (x) => {x.changeType()}
          ,title:'Show menu info by category Teachers, Students, Other, All'},
          {id:1, name: 'Search',icon:'mdi-card-search', task:'swop', swop:[true,false],value: false
          ,title:'Open a small search field at start of functions'},
          {id:2, name: 'MenuSize',icon:'mdi-resize', task:'swop', swop:[true,false],value: false
          ,title:'Make the menu buttons smaller or larger'},
          {id:3, name: 'Info',icon:'mdi-information', task:'swop', swop:[true,false],value: false
          , title:'See more or less information next to each button'},
          {id:4, name: 'Refresh',icon:'mdi-refresh', task:'action', func: (x) => {x.loadFreshResultSetToCache()}},

        ]

    }),
    computed:{
    },
    methods:{
      catchToolbarClick(e) {
        btw.handleDisplayChanges(this.toolList, e)
        btw.execute(this,e)
        this.menuSearch = this.toolList[1].value
        this.menuSmall = this.toolList[2].value
        this.menuInfo = this.toolList[3].value
      },
      goToCalendar() {
        this.$router.push({ name: 'CalendarTeacher', params: {menemonic: this.wieOmTeWys} })
      },
      changeType() {

        if (this.menuType == 'all') { this.menuType = 'admin'; this.toolList[0].name = `Access : ${this.menuType}` ; return}
        if (this.menuType == 'admin') { this.menuType = 'teacher'; this.toolList[0].name = `Access : ${this.menuType}`; return}
        if (this.menuType == 'teacher') { this.menuType = 'student'; this.toolList[0].name = `Access : ${this.menuType}`; return}
        if (this.menuType == 'student') { this.menuType = 'other'; this.toolList[0].name = `Access : ${this.menuType}`; return}
        if (this.menuType == 'other') { this.menuType = 'all'; this.toolList[0].name = `Access : ${this.menuType}`; return}
      },
      async CallAsyncFunction() {
          if (this.getZml.login.isAuthenticated && this.getZml.login.username == 'werner') {
           this.loading = true
           let joke = await zData.randomChuckNorris();
           this.joke = joke.value
           if  (this.joke && ( this.joke.indexOf('sex')
                            || this.joke.indexOf('prince albert')
                            || this.joke.indexOf('condom')
                            || this.joke.indexOf('placen')
                            || this.joke.indexOf('fuck')
                            || this.joke.indexOf('anal')
                            || this.joke.indexOf('pregna')
                            || this.joke.indexOf('bondag')
                            || this.joke.indexOf('gay'))) {
               joke = await zData.randomChuckNorris();
               this.joke = joke.value
           }
           this.loading = false
          }
      },
      initialize() {
          //if (!this.getZml.functions.length) this.loadFunctions()
          console.log('Super = ', this.$super)
      },
      loadFreshResultSetToCache() {
          console.log('refresh data : subs = ', this.getZml.subjects.length)
          this.getZml.subjects.length = []
          this.getZml.persMenemonic.length = []
          this.getZml.place.length = []
          zData.initialData('Refresh Data', this.initialize)
          zData.loadSuper(this)
        }
    },
    created() {
      zData.quickLoadInitialData('HomeAdmin.vue : Load Data for incase', this.initialize)
      this.CallAsyncFunction()

    },
    mounted() {
        cl('Mount',this.$options.name)
        //cl('AdminHome Load Joke',this.today,this.tomorrow)

        //this.menuType = this.getZml.login.type
        this.wieOmTeWys = this.getZml.login.username



    }
}
</script>

