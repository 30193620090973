<template>
  <span class="text-caption float-center">
    <v-btn v-if="checkMed != 0" fab  class="ml-10 ma-2"  @click="gotoMedical" title="Medical Info">
      <v-icon large color="red"> mdi-medical-bag</v-icon>
    </v-btn>
    <span v-else class="ml-10 ma-2">.</span>
      <v-btn v-for="f in fab" :key="f.listname"
             v-if="f.icon"
            fab class="ma-2" xcolor="f.color" @click="fabClick(f)"
            :title="f.listname">
        <v-icon large :color="f.color"> {{ f.icon }}</v-icon>
      </v-btn>
    </span>
</template>

<script>
import { zmlConfig } from '@/api/constants';
import { zmlFetch } from '@/api/zmlFetch';
export default {
    name:"StudentFab",
    components:{},
    props: ['studentid'],
    data: () => ({
      checkMed: 0,
      fab:[],
    }),
    computed: {
    },
    methods: {
      displayMedical() {
        if (!this.studentid) return
        let ts = {}
        ts.task = 'OneValueSql'
        ts.sql = `select count(*) cnt from dkhs_studentnote where studentid = ${this.studentid} and note like 'Medical'`
        ts.api = zmlConfig.apiDKHS
        zmlFetch(ts, this.getMedCount, () => {this.checkMed = false} )
        ts.task = 'plainSql'
        ts.sql = `select listname from hw_classlist where id in (select classlistid from hw_classliststudent where studentid = ${this.studentid})`
        ts.api = zmlConfig.apiDKHS
        zmlFetch(ts, this.getFab, () => {this.fab = []} )

      },
      fabClick(item) {
        if (item.link.indexOf('http') > -1) {
          //alert('start a link')
          window.open(item.link,'_' + 'ko_external')
        }
      },
      getFab(response) {
        if ('error' in response  && response.error.indexOf('no rows') > -1) {
          this.fab = []
        } else {
          this.fab = response
        }
        //console.log('fab=', this.fab, response)
        this.fab.forEach((e,i) => {
          if (e.listname.toUpperCase().indexOf('PHOTO') > -1) {
             e.icon = 'mdi-camera'
             e.color = 'purple'
             e.link = 'https://www.kuiliesonline.co.za/to/INFO/photoclub.htm'
          }
          if (e.listname.indexOf('Recycle') > -1) {
             e.icon = 'mdi-recycle'
             e.color = 'green'
             e.link = 'https://www.kuiliesonline.co.za/to/INFO/recycle.htm'
          }
          if (e.listname.indexOf('RVL') > -1) {
             e.icon = 'mdi-vote-outline'
             e.color = 'blue'
             e.link = 'https://www.kuiliesonline.co.za/to/Valedictory'
          }
          if (e.listname.indexOf('Hiking') > -1) {
             e.icon = 'mdi-walk'
             e.color = 'orange'
             e.link = 'https://www.kuiliesonline.co.za/to/Hiking'
          }
          if (e.listname.indexOf('Terrein') > -1) {
             e.icon = 'mdi-border-outside'
             e.color = 'purple'
             e.link = 'https://www.kuiliesonline.co.za/to/News'
          }
          if (e.listname.indexOf('Excel in Sport') > -1) {
             e.icon = 'mdi-biathlon'
             e.color = 'gold'
             e.link = 'https://www.kuiliesonline.co.za/to/News'
          }
          if (e.listname.indexOf('Choir') > -1) {
             e.icon = 'mdi-music-note-eighth'
             e.color = 'gold'
             e.link = 'https://www.kuiliesonline.co.za/to/News'
          }
          if (e.listname.indexOf('Music Students') > -1) {
             e.icon = 'mdi-playlist-music'
             e.color = 'gold'
             e.link = 'https://www.kuiliesonline.co.za/to/News'
          }
          if (e.listname.indexOf('1st') > -1) {
             e.icon = 'mdi-seal-variant'
             e.color = 'green darken-3'
             e.link = 'https://www.kuiliesonline.co.za/to/1stTeam'
          }


        })
        for(let i = this.fab.length-1; i >= 0 ; i--) {
           if (!this.fab.icon) this.fab.splice(i,0)
        }
        //console.log('fabDone=', this.fab)
      },
      getMedCount(resp) {
        this.checkMed =  resp
      },
      gotoMedical() {
        this.$emit('goto', 'Medical')
      },
    },
    mounted() {
      this.$cs.l('Mounted', this.$options.name)
      this.displayMedical()
    },
    watch:{
        studentid: {
            handler() {
               this.displayMedical()
            }
        }
    }

}
</script>