<template>
<!--
  in BaseDialog, the v-dialog is handled from parent
  in BaseButtonDialog, the v-dialog is handled from child.
-->
  <v-btn :color="color"
         :icon="(iconName.length > 3 && buttonText.length == 0) ? true : false || $vuetify.breakpoint.smAndDown"
         :x-small="$vuetify.breakpoint.mdAndDown"
         :small="$vuetify.breakpoint.lgAndDown"
         @click="show=!show"
         :title="header"
         :hidden="show"
         class="mx-2"
  >
   <v-icon v-if="iconName.length > 3">
      {{ iconName }}
   </v-icon>
     <span v-if="!iconName || !$vuetify.breakpoint.smAndDown"> {{ buttonText }} </span>
      <v-dialog v-model="show"
               :max-width="localMaxWidth"
                xwidth="auto"
                max-height="500"
                style="position:relative"
               :fullscreen="$vuetify.breakpoint.smAndDown || expand"
      >
       <!-- FROM HERE IT IS SAME AS BASEDIALOG - EXCEPT for $emit on close, rather than show=false-->

     <v-card :max-width="localMaxWidth" :class="formTop" ref="vcard">
      <v-toolbar dense flat outlined tile
                 height="35px"
                 max-height="40px"
                 color="smalltoolbargray">
          <v-btn icon x-small @click="expand = !expand">
            <v-icon v-show="expand">mdi-arrow-collapse</v-icon>
            <v-icon v-show="!expand">mdi-drag-horizontal-variant</v-icon>
          </v-btn>
          <v-spacer/>
          <slot name="header" class="text-md-h4 font-weight-medium">{{ header }}</slot>
          {{  localMaxWidth }}
          <v-spacer/>
          <v-btn icon x-small @click="show=false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <br>
      <v-card-text :style="formSpace" >
        <slot name="info">
          {{ info }}
        </slot>
      </v-card-text>
      <v-card-actions :style="formSpace">
        <slot name="actions"></slot>
      </v-card-actions>
     </v-card>
  <!-- UNTIL HERE IT IS SAME AS BASEDIALOG-->

      </v-dialog>
  </v-btn>
  </template>

  <script>
  function cl(...args) {    console.log('BD',...args) }
  export default {
      name:"BaseDialog",
      props:{
          buttonText:{type:String, default:''},
          iconName:{type:String, default:''},
          info:{type:String, default:'Please supply some text'},
          header:{type:String, default:''},
          color:{type: String, default: ''},
          maxWidth:{default:900},
          paddingRight:{type: String,default:'120px'}
      },
      data: () => ({
       show:false,
       expand: false,
       images:['learn/Rect-Gray-Respect.svg','learn/Rect-Gray-DKHSLogo.svg','learn/Rect-Gray-Integrity.svg','learn/Rect-Gray-KlasGee.svg','learn/Rect-Gray-StudentRead.svg',
               'learn/Rect-Gray-StudentComputerHome.svg','learn/Rect-Gray-BlueStudentWithLaptop.svg','learn/Rect-Gray-WIFI.svg','learn/Rect-Gray-Stats.svg','learn/Rect-Gray-Hiking.svg',
               'learn/Rect-Gray-Message.svg','learn/Rect-Gray-CalendarAdd.svg','learn/Rect-Gray-USB.svg','learn/Rect-Gray-Internet.svg','learn/Rect-Gray-Equality.svg',
               'learn/Rect-Gray-AttendanceHandInLug.svg'],
      }),
      methods:{
        test() {
          const element = document.getElementById("bdid")
          let text = "clientHeight: " + element.clientHeight + "px<br>"
          text += "clientWidth: " + element.clientWidth + "px"
          alert(text)
        },
      },
      computed:{
        localMaxWidth() {
          if (screen.width < 400) return screen.width - 4
          return 400
       },
       formTop() {
          if (!this.expand || this.$vuetify.breakpoint.mobile) return ''
          let thebox = 'thebox' + Math.floor(Math.random() * 5)
          return this.$vuetify.breakpoint.mdAndUp ? thebox : ''
        },
        formSpace() {
          if (!this.expand || this.$vuetify.breakpoint.mobile) return ''
          return this.$vuetify.breakpoint.mdAndUp  ? 'padding-right: ' + this.paddingRight : ''
        }
      },
      mounted() {
        //this.localMaxWidth = this.MaxWidth || 600
        //cl(' mounted', window.innerWidth, this.formTop)
      },
      activated() {
        //cl(' activated', window.innerWidth, this.formTop)
      },
      created() {
        //cl(' created', screen.width, this.formTop)
      },
      destroyed() {
        //cl(' destryed', this.localMaxWidth, this.formTop)
      },
      watch: {
        show() {
          cl('show',this.formTop)
        }
      }
  }
  </script>

  <style scoped>
  div.thebox0
  {
    fill: currentColor;
    box-sizing: border-box;
    padding-right: 5px;
    /* background-image: url('~@/assets/Rect-Gray-Equality.svg'); */
    /* background-image: url('~@/assets/Rect-Gray-KlasGee.svg'); */
    background-image: url('https://kuiliesonline.co.za/img/upload/Rect-Gray-Equality.svg');
    background-size: 120px auto;
    background-repeat: repeat-y;
    background-attachment: scroll, local;
    background-origin: content-box;
    background-position: top right;
  }
  div.thebox1
  {
    fill: currentColor;
    box-sizing: border-box;
    padding-right: 5px;
    background-image: url('https://kuiliesonline.co.za/img/upload/Rect-Gray-AttendanceHandInLug.svg');
    /* background-image: var(--url1); */
    background-size: 120px auto;
    background-repeat: repeat-y;
    background-attachment: scroll, local;
    background-origin: content-box;
    background-position: top right;
  }
  div.thebox2
  {
    box-sizing: border-box;
    padding-right: 5px;
    background-image: url('https://kuiliesonline.co.za/img/upload/Rect-Gray-Integrity.svg');
    background-size: 120px auto;
    background-repeat: repeat-y;
    background-attachment: scroll, local;
    background-origin: content-box;
    background-position: top right;
  }
  div.thebox3
  {
    box-sizing: border-box;
    padding-right: 5px;
    background-image: url('https://kuiliesonline.co.za/img/upload/Rect-Gray-KlasGee.svg');
    background-size: 120px auto;
    background-repeat: repeat-y;
    background-attachment: scroll, local;
    background-origin: content-box;
    background-position: top right;
  }
  div.thebox4
  {
    box-sizing: border-box;
    padding-right: 5px;
    background-image: url('https://kuiliesonline.co.za/img/upload/Rect-Gray-Message.svg');
    background-size: 120px auto;
    background-repeat: repeat-y;
    background-attachment: scroll, local;
    background-origin: content-box;
    background-position: top right;
  }
  div.thebox5
  {
    box-sizing: border-box;
    padding-right: 5px;
    background-image: url('https://kuiliesonline.co.za/img/upload/Rect-Gray-BlueStudentWithLaptop.svg');
    background-size: 120px auto;
    background-repeat: repeat-y;
    background-attachment: scroll, local;
    background-origin: content-box;
    background-position: top right;
  }
  </style>