<template>
  <div>
    <!-- homestudent 14000/14000-->
    <!-- <z-menu
                :permanent="true"
                :expandOnHover="true"
                :floating="true"
                :passed="['cancel', 'save', 'load']"
        /> -->
    <v-toolbar color="primary">
        <v-card color="primary" width="100%" class="pa-3">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div class="float-left ma-2">
              AVAILABLE OPTIONS FOR {{ studentid }}
            </div>
            <div class="float-center ma-1">
              <avatar :username="getZml.login.fullname"
               @clickAvatar="showPhoto"
               :src="photo" />
            </div>
            <div class="float-right ma-2">
              {{ getZml.login.fullname}} / {{ gradeToShow.g }}{{ gradeToShow.c }}  ( {{ getZml.login.logins}} )
            </div>
          </div>
        </v-card>
    </v-toolbar>

    <v-toolbar  dense  row  wrap color="primary">
      <v-spacer />
      <!-- <base-tool-button to="/addphoto"
               class="mt-1 mr-2 mb-2 ml-2" color="secondary" icon="mdi-camera"
               title="Add sport id Photo for someone"
      >Sport ID Photo</base-tool-button> -->
      <!-- <base-tool-button @click="showAttendanceBadge"
               class="mt-1 mr-2 mb-2 ml-2" color="secondary" icon="mdi-wifi"
               title="Attendance badge for scanning">
        Attendance Badge
      </base-tool-button> -->
      <base-tool-button @click="showWiFiUsername"
                class="mt-1 mr-2 mb-2 ml-2" color="secondary" icon="mdi-qrcode-scan"
               title="Show and Explain Wi-fi username/password">
        WCGSCHOOLS WiFi
      </base-tool-button>
      <base-tool-button @click="showTimetable = !showTimetable"
               class="mt-1 mr-2 mb-2 ml-2" color="secondary" icon="mdi-timetable">
        Time Table
      </base-tool-button>
    </v-toolbar>
    <v-dialog v-model="showTimetable" max-width="900">
      <v-btn  color="primary" @click="showTimetable = false"> close </v-btn>
      <calendar-student :studentid="studentid" :grade="gradeToShow.g" :gclass="gradeToShow.c" />
    </v-dialog>

    <v-dialog v-model="showPhotos" width="300" :scrollable="false" :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-card>
        <v-btn icon absolute top right title="Click here to close" @click="showPhotos=false">
          <v-icon color="indigo">mdi-close</v-icon>
        </v-btn>
        <student-photo-list :studentid="studentid" @foundPhoto="weHaveIt" />
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" lg="6" md="12">
        <v-layout>
          <menu-list-old functiongroup="student" />
          <menu-list-old functiongroup="other" />
        </v-layout>

      </v-col>
      <v-col cols="12"  lg="6" md="12">
        <student-email-list xclass="ma-2 pa-2" heading="Your parents emails for newsletters" :studentid="studentid" color="primary" />
        <student-fab :studentid="studentid" />
        <v-card class="pt-2">
          <v-card-title class="blue">
            School Photos
          </v-card-title>
          <v-card-text class="mt-2">
            <v-btn class="ma-2 pa-2" to="/folder/PHOTOS/BristolGrammarSchool"> Bristol Grammar School 2024</v-btn>
            <v-btn class="ma-2 pa-2" to="/folder/PHOTOS/htsRugby20240803"> Rugby HTS August 2024</v-btn>
            <v-btn class="ma-2 pa-2" to="/folder/PHOTOS/202405HopefieldHokkie"> Hopefield Hockey</v-btn>
         </v-card-text>
        </v-card>

        <v-card class="pt-2">
          <v-card-title class="blue">
            Your Uploads
          </v-card-title>
          <student-uploads :studentid="studentid" />
        </v-card>
     </v-col>
    </v-row>

    <!-- xxxx = {{  gradeToShow.g + gradeToShow.c }}
    sss = {{ studentid }} -->
    <v-layout row wrap align-content-center justify-space-between class="ma-1 pa-1">
      <v-expansion-panels v-if="getZml.login.isAuthenticated">
        <v-expansion-panel>
          <v-expansion-panel-header title="Link to subjects">Subjects</v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- <student-subject-list :studentid="studentid" color="primary" /> -->
           <student-subject-list v-if="['G10','G11','G12'].includes(gradeToShow.g)" :studentid="studentid" color="white darken-1" />
           <student-subject-list-89 v-else :gradeclass="gradeToShow.g + gradeToShow.c" color="white darken-2" />
          </v-expansion-panel-content>
        </v-expansion-panel>

       <v-expansion-panel>
         <v-expansion-panel-header title="Show Email Bulletins">
           Merits</v-expansion-panel-header>
         <v-expansion-panel-content>
           <student-merit :studentid="studentid"  xcolor="grey" />
         </v-expansion-panel-content>
       </v-expansion-panel>
     </v-expansion-panels>
        <base-title-expand v-if="studentid" heading="Student General Class Conduct">
          .<v-btn @click="$cs.scrollToTop()" x-small color="primary" title="Scroll to top" class="float-right elevation-2 noprint">
           <v-icon x-small > mdi-arrow-up</v-icon>
          </v-btn>
          <student-gen-list-cal :studentid="studentid"  color="white darken-1" />
       </base-title-expand>
    </v-layout>

    <!-- <menu-list-old functiongroup="other" /> -->
    <!--student-name-card :studentList="studentList"  maybe add the current student namecard here.. -->

  </div>
</template>

<script>
import { zData } from "@/api/zGetBackgroundData.js"
import { doStuff } from '@/api/buttons'
import { util } from '@/api/util'
import { infoSnackbar } from '@/api/GlobalActions';
import { getters } from "@/api/store";
import MenuListOld from '@/components/MenuListOld.vue';
import StudentEmailList from '@/components/student/StudentEmailList'
import StudentPhotoList from '@/components/student/StudentPhotoList'
import StudentUploads from '@/components/student/StudentUploads'
import StudentSubjectList from '@/components/student/StudentSubjectList'
import StudentSubjectList89 from '@/components/student/StudentSubjectList89'
import StudentGenListCal from '@/components/student/StudentGenListCal'
import StudentMerit from '@/components/student/StudentMerit'
import StudentFab from '@/components/student/StudentFab.vue'
import CalendarStudent from '@/components/CalendarStudent.vue'
import BaseToolButton from '@/components/base/BaseToolButton.vue'
import Avatar from '@/components/base/Avatar'
import BaseTitleExpand from '@/components/base/BaseTitleExpand.vue'
import { zmlLog } from '@/api/zmlLog.js';

function cl(...args) {    console.log('HomStu',...args) }

export default {
  name:"StudentHome",
  components:{
      MenuListOld
    , StudentEmailList
    , StudentSubjectList
    , StudentSubjectList89
    , StudentMerit
    , StudentPhotoList
    , StudentFab
    , CalendarStudent
    , StudentUploads
    , Avatar
    , BaseToolButton
    , BaseTitleExpand
  },
  data: () => ({
    getZml: getters.getState({ object: "gZml" }),
    showCal:true,
    cards: ['Today', 'Yesterday'],
    gradeToShow:{g:'', c:''},
    photo:'',
    studentid:'',
    showPhotos:false,
    showTimetable: false
  }),
  computed:{
    menuFilterList() {
            if (!this.getZml) return 0;
            return this.getZml.functions.filter(a => function()
            {
                if (a.accesstype == 'student' || a.accesstype == 'other')
                    return 1
                else
                    return 0
                }
            )
        }
  },
  methods:{
    showWiFiUsername() {
      this.$router.push({ name: 'wifi', params: {studentid: this.studentid, editmode: false} })
    },
    showAttendanceBadge(){
         window.open(`https://kuiliesonline.co.za/att/18081AttendanceBadge.png`)
    },
    showPhoto(ev) {
        //We only load the photo if he clicks on the avatar icon.
        //Then we replace the "initialicon" with a actual photo - if we have one.
        cl('He clicked the icon - display photos if there are some', ev)
        this.showPhotos = true
    },
    weHaveIt(ev) {
        cl('arrived!!', ev)
        this.photo=ev
    },
    cardColor(type) {
        switch (type) {
            case 'teacher' : return "light-green lighten-3"
            case 'student' : return "green lighten-2"
            case 'admin' : return "green accent-3"
            default : return "orange lighten-4"
        }
    },
    click(what) {
        if (doStuff(this.$router,what.payload) == 0) {
            if (what.payload.substr(0,4).toLowerCase() == 'http') {
                window.open(what.payload,'_' + 'ko_external')
            } else {
                infoSnackbar('Sorry, we do not handle ' + what.payload + ' yet!' )
            }
        }
    },
    loadError(response) {
        alert(response)
    },
    showData(response) {
       this.getZml.functions = response
    },
    initialize() {
        cl(util.getNum('009'), this.getZml.login.schoolno, this.getZml.login.username )
        cl('home2:', this.gradeToShow.g + this.gradeToShow.c)
        this.showCal = true;
        //this.loadFunctions()
    }
  },
  created() {
    zData.quickLoadInitialData('Load Data for incase', this.initialize)
  },
  mounted() {
    cl('mount', this.$options.name)
    zmlLog('Home', this.getZml.login.fullname, `${this.getZml.login.schoolno}`)
    this.studentid = this.getZml.login.schoolno
    cl('grade',this.getZml.login.grade,this.getZml.login.grade.length)
    if (this.getZml.login.grade.length == 3 ) {
       this.gradeToShow.g = this.getZml.login.grade
       this.gradeToShow.c = this.getZml.login.gclass
    } else {
       this.gradeToShow.g = 'G09'
       this.gradeToShow.c = 'E4'

    }
  }
}
</script>

