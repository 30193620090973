<template>
  <!-- This show the student general inside StudentInfo which is in view folder -->
<v-container v-if="['admin','teacher'].includes(getZml.login.type)" fluid>
  <z-span> StdGHL </z-span>
 <v-card class="mx-auto"
         :color="color"
         elevation="2"
  >
   <v-card-title class="headline ma-1"> {{ reportOnFindings }}</v-card-title>
   <v-card-text v-if="showCalendar == false">
     <v-data-table
         :headers="genListHeader"
         :items="generalList"
         :items-per-page="5"
         class="ma-1 pa-2"
         @click:row="clickOnRow"
         mobile-breakpoint="0"
     />
     </v-card-text>
     <v-card-text v-if="showCalendar == true">
     <quick-calendar-display  class="ma-1 pa-2"
       v-if="showCalendar && genListEvt && genListEvt.length"
       :heading="`Conduct ${studentid}, ${studentSurname || ''}`"
       :passedEvents="genListEvt"
     />
   </v-card-text>
   <v-card-actions v-if="generalList">
    <v-btn @click="exportToCsv" small>
      Show Data for Export
    </v-btn>
     <!-- <v-btn @click="showResult = !showResult" small>
        Show Data for Export
    </v-btn> -->
    <v-btn @click="showCalendar = !showCalendar" small>
        <span v-if="!showCalendar">Calendar</span><span v-else> Columns </span>
    </v-btn>
    <v-btn @click="showTimetable = !showTimetable" small>
        TimeTable  <!-- :to="`/calstud/${studentid}`" -->
    </v-btn>
   </v-card-actions>
  </v-card>

    <!-- <v-dialog v-model="showResult" fullscreen>
     <v-card color="red" v-if="showResult && generalList">
      <front-json-to-csv :json-data="generalList"
                         :csv-title="`GenConduct List ${studentid} ${studentSurname}`"
                         @hideModal="showResult = false">
      </front-json-to-csv>
     </v-card>
    </v-dialog> -->

    <v-dialog v-model="showTimetable" max-width="900">
      <v-btn small color="primary" @click="showTimetable = false"> close </v-btn>
      <calendar-student :studentid="studentid" />
    </v-dialog>

</v-container>
</template>
<script>
import { zmlFetch } from "@/api/zmlFetch";
import { zRoute, getters } from "@/api/store";
//import FrontJsonToCsv from '@/api/csv/FrontJsonToCsv.vue'
import QuickCalendarDisplay from '@/components/cal/QuickCalendarDisplay.vue'
import CalendarStudent from '@/components/CalendarStudent.vue';
import { infoSnackbar,errorSnackbar } from '@/api/GlobalActions';
export default {
    name:"StudentGenListCal",
    props: ['studentid','color'],
    components: {
        QuickCalendarDisplay
      , CalendarStudent
      //, FrontJsonToCsv
    },
    data: () => ({
      getZml: getters.getState({ object: "gZml" }) ,
      generalList:null,
      showCalendar: true,
      showTimetable: false,
      genListHeader: [
        //{text: 'id',       align: 'start',  value: 'listdataid' },
          {text: 'Date and Time',  align: 'start',  value: 'changedate' },
          {text: 'Teacher',    align: 'start',  value: 'user_name' },
          {text: 'Session',   align: 'start',  value: 'sessionid' },
          {text: 'Period', align: 'start',  value: 'period' },
          //{text: 'Class',    align: 'start',  value: 'gclass' },
          //{text: 'Grade',    align: 'start',  value: 'grade' },
          {text: 'Status',    align: 'start',  value: 'status' },
        ],
      showResult:false,
      genListEvt:[],
      reportOnFindings: 'General Homework Lists'
    }),
    methods:{
      exportToCsv() {
      // alert('export to jsontocsvfunction via a push')
        let name = 'FrontJsonToCsvFunction'
        let pIdx = zRoute.load(name, { csvTitle: `GenConduct List ${this.studentid} ${this.studentSurname}`
                                  , unique:'SL-StudCon'
                                  , jsonData: this.generalList
                                  , footer:'EBDs'
                                  , small: false})
        this.$router.push(zRoute.run(name, pIdx))
      },
      clickOnRow(e) {
        console.log('genlist clicked on row',e)
      },
      getGenList() {
        if (this.studentid) {
           let sl = { task: 'plainSql'
                    , sql:`select listdataid , user_name, placeid, period, dayno
                                , d.studentid
                                , substring_index(substring_index(sessionid,'-', 1),'.',-1) sessionid
                                , changedate, status
                            from dkhs_genlistdata d, dkhs_learner l\
                           where d.userid = l.userid\
                             and d.studentid = ${this.studentid} order by changedate DESC`
           }
           zmlFetch(sl, this.processAfterFetch);
        }
      },
      processAfterFetch(response) {
        this.studentSurname = ''
        if (!response.error) {
           this.generalList= response
           this.studentSurname = `: ${response[0].surname} : ${response[0].grade}`
           this.reportOnFindings = 'We found entries - processing ' + this.generalList.length + ' entries'
           this.loadEvents()
        } else {
            this.generalList = []
            errorSnackbar('We did not find any "homework" records, ') // + response.error)
            this.reportOnFindings = 'We did not find any entries'
        }
      },
      loadEvents() {
        let sql =  `SELECT 9999 id\
       , DAYNAME(substr(dt.fulldate,1,10)) dayname \
       , dt.fulldate fulldate\
       , dt.fulldate start\
       , 'No Entry' name\
       , concat('day',dayno) sessionid\
       , '' staff\
       , 'light-blue' color\
   FROM dkhs_date dt \
WHERE dt.fulldate > now() - INTERVAL 300 DAY \
 AND DAYNAME(substr(dt.fulldate,1,10)) NOT IN ('Sunday','Saturday') \
union all \
SELECT d.listdataid id\
      , DAYNAME(substr(changedate,1,10)) dayname \
      , substr(changedate,1,10) fulldate\
      , changedate start\
      , concat(substring_index(substring_index(sessionid,'-', 1),'.',-1),':',status) name
      , sessionid\
      , l.user_name staff\
      , 'dark-blue' color\
 FROM dkhs_genlistdata d, dkhs_learner l\
 where d.studentid = ${this.studentid}\
   and d.userid = l.userid\
   AND substr(changedate,1,10) > now() - INTERVAL 300 DAY \
ORDER BY fulldate`
      zmlFetch({task:'plainSql', sql:sql} , this.afterwards)
      },
      afterwards(response) {
        this.genListEvt = response
      },
    },
    mounted() {
        this.$cs.l('Mounted',this.$options.name,  this.studentid)
        if (this.studentid) {
           this.getGenList()
        }
    },
    watch: {
        studentid(n,o) {
            if (n != o) this.getGenList()
        }
    }
}
</script>