// plugin folder

// Read this as a way to pull in  all components in "global" directory or whatever...
// https://dev.to/nkoik/-vuex-pattern-smart-module-registration-15gc

// This exports the plugin object.
export default {
  // The install method will be called with the Vue constructor as the first argument, along with possible options
  install (Vue, options) {

     let pluginName = 'werPlugin'
     let caller = 'unknown'

     if (this.$options && this.$options.name) {
       caller = '0'+this.$options.name
      } else {
        if (this._uid) {
         caller = '1'+this.uid
        } else {
         caller = '2'+this.install.name
        }
     }

  console.log('Installing ', pluginName,caller, options, this)

 // Add components or directives to your plugin, so it will be installed globally to your project.
     if (options.appLayout !== false) Vue.component('AppLayout' , resolve => { require(['@/layouts/AppLayout']          , resolve) })
     if (options.zSpan !== false) Vue.component('zSpan'     , resolve => { require(['@/components/global/zSpan.vue'], resolve) })
     //if (options.?? !== false) Vue.component('???'     , resolve => { require(['@/components/global/???.vue'], resolve) })

 // Add `Vue.mixin()` to inject options to all components.
 // The lines below give you and idea off how many times this plugin is called! (Each little btn!!)

   //   Vue.mixin({
   //       // Add component lifecycle hooks or properties.
   //       created() {
   //          if (this.$options.name) {
   //             console.log(`${pluginName} loaded for tag! ${this._uid} , ${this.$options.name}`)
   //             caller = this.$options.name
   //          } else {
   //             if (this.$options._componentTag){
   //                console.log(`${pluginName} loaded for tag! ${this._uid} , ${this.$options._componentTag}`)
   //             } else {
   //                if (this.$attrs && Object.keys(this.$attrs).length) {
   //                   console.log(`${pluginName} loaded for att! ${this._uid} , ${this.$attrs}`)
   //                } else {
   //                      console.log(`${pluginName} loaded for this! ${this._uid}`)
   //                }
   //             }
   //          }
   //       }
   //   })


 // Add Vue instance methods by attaching them to Vue.prototype.
     Vue.helloWernerPlugin = (value) => console.log('WernerPlugin Hello', value)
     Vue.prototype.$wernerProperty = 'This is a Vue instance property from Werners Plugin.'
     Vue.prototype.$helloWernerPlugin = Vue.helloWernerPlugin

 // Call playsound from anywhere - for mp3 or .wav files
     Vue.prototype.$playSound = (path, volume = .5) => {
      var audio = new Audio(path);
      audio.volume = volume
      audio.play();
    }
 // Expose stuff you can use quickly
 // this.$cs.l('Some message')
    Vue.prototype.$cs = {
      l: (...args) => {
            // console.log('Z'+caller,...args);
      },
      scrollToTop: (containerID) => {
          let e = document.getElementById(containerID || "app")
          if (e) { e.scrollIntoView({ behavior: "smooth" })} else { cs.l('ELEMENT DOES NOT EXIST')}
      },
    }
// used in router
    Vue.prototype.$history = window.history;

// Mostly static variables we use around system
    Vue.prototype.$super = Vue.observable({
      user: false,  // When true, the person is a superUser
      fullname: '', // as from getZml.log
      userid: '',   // as from getZml.log
      message: 'message', // Not decided yet
      test: 'test',       // Not decided yet
      isAuthenticated: false  // plan on storing isAuth here - not done yet.
    });


  }
} //End Of Export Default


if (typeof window !== 'undefined' && window.Vue) {
   window.Vue.use(wernerPlugin,{hello:'from no module system'}) //See : https://www.digitalocean.com/community/tutorials/vuejs-creating-custom-plugins
 }

/*
Calling these functions and properties - you do it like this"
        cl('$wernerProperty', this.$wernerProperty, this)
        //this.$wernerPlugin.helloWernerPlugin()
        cl('plugin = ', this.$helloWernerPlugin('some parameter'))
*/