function cl(...args) {    console.log('BTW',...args) }


export const btw = {
   hello: (p1) => {cl('hello from (baseToolWorker.JS) ' , p1)},
   name: "bateToolWorker",
   handleDisplayChanges(toolList, evt) {
    cl('handleDisplayChanges',toolList, evt)
    cl('handleDisplayChanges', evt.id)
    let tBut = {}
    toolList.forEach(element => {
        if (element.id == evt.id) tBut = element
    });
    cl('start',tBut.task)
    if (tBut.task == 'swop') {
        cl('START swop obj value is: ', tBut)
        let curValue = tBut.value
        let newValue = ''
        if (curValue == tBut.swop[0]) {
          newValue = tBut.swop[1]
        } else if (curValue == tBut.swop[1]) {
          newValue = tBut.swop[0]
        } else {  //we should give warning here - we have a third wrong value
          alert('third wrong value')
          newValue = tBut.swop[1]
        }
        //Swop the values
        tBut.value = newValue
        //replace the string values(hide vs show)

        let Tst = tBut.name.replace(curValue, newValue)
        cl('replace But',tBut.name,'Tst',Tst)
        if ( tBut.name.toUpperCase() ==  Tst.toUpperCase()) {
          tBut.name = tBut.name.replace(newValue, curValue)
          cl('replace - still same, use reverse', tBut.name)
        } else {
          tBut.name = Tst
          cl('replace - NOT the same, use tst', tBut.name)
        }
        cl('END swop obj value is now: ', tBut )
        return
    }
    if (tBut.task == 'to') {
        cl('Let caller handle (push router)',tBut.value)
        return
    }
    if (tBut.task == 'print' && tBut.value) {
        cl('Let caller handle (print)',tBut.value)
        return
    }
    if (tBut.task == 'action') {
        cl('Let caller handle (action)')
        return
    }
    cl('end - noluck', tBut.task, tBut.value)
    return
   },
   execute(that,e) {
     cl('execute function on', e)
     if (e.task == 'to') {
        if (e.value) {
          that.$router.push({ name: e.value})
          return
        }
        if (typeof e.path == 'function') {
          that.$router.push({ path: e.path(that)})
          return
        }
        if (e.path) {
          that.$router.push(e.path)
          return
        }
        that.ss('We do not understand what to do with TO in this case...')
     }
     if (e.task == 'action') {
      console.log('E action', e)
      if (typeof e.func == 'function') {
         console.log('E action caller' )
         e.func(that)
      } else {
        that.ss('We need a function to execute this action')
      }
     }
   }
}



