<template>
  <v-container fluid
               class="ma-0 pa-0 no-print">
    <v-navigation-drawer v-if="toolList"
      v-model="appBar"
      absolute
      width="250"
      right
      temporary>

      <v-list nav dense rounded>
        <v-list-item @click = "appBar=false">
          <v-list-item-icon><v-icon>mdi-close</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title title="Close this Menu">Close Menu</v-list-item-title></v-list-item-content>
        </v-list-item>
        <hr width="60%" />
        <v-list-item v-if="t && t.name" v-for="(t,i) in toolList" :key="i" @click="emitter(t.id)" >
          <v-list-item-icon>
            <v-icon :color="getColorMenu(t)"> {{t.icon || i }} </v-icon>
          </v-list-item-icon>
         <v-list-item-content>
          <v-list-item-title>
            {{ t.name || t.task || t.icon}}  {{  getColorMenu(t)  }}
          </v-list-item-title>
         </v-list-item-content>
        </v-list-item>
      </v-list>

      <hr width="40%" class="ma-2" />

      <slot name="menu" />

      <hr width="20%" class="ma-2" />
      <slot name="onlynav" />
    </v-navigation-drawer>

    <v-toolbar elevation="3"
               :color="color || 'primary'"
               :src="showImg ? 'img\\toolbar.jpg' : ''"
               :dense="$vuetify.breakpoint.lgAndDown"
               :extended="$vuetify.breakpoint.xlOnly"
               rounded
               :collapsed="false"
               class="no-print">
      <v-app-bar-nav-icon xv-if="toolList && toolList.length > 0" @click="appBar = !appBar">
        <v-icon> mdi-menu</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title v-if="toolbarName">        {{ toolbarName }}      </v-toolbar-title>

      <v-overlay v-if="loading" :value="loading">
        <v-progress-circular indeterminate size="84">
          Thinking ...
        </v-progress-circular>
      </v-overlay>
      <v-spacer />
      <v-toolbar-items xv-if="toolList.length" class="ma-2 pa-2">
        <slot name="menu" />
        <v-btn v-if="t && t.name"
               v-for="(t,i) in toolList" :key="i"
               class="ml-2 pa-1"
               :x-small="$vuetify.breakpoint.mdAndDown"
               :small="$vuetify.breakpoint.lgAndDown"
               :color="getColor(t)"
               :icon="!!$vuetify.breakpoint.smAndDown"
               :to="to"
               :title="t.title || t.name"
               @click="emitter(t.id)">
          <v-icon v-if="t.icon || $vuetify.breakpoint.smAndDown" :color="$vuetify.breakpoint.mdAndDown ? 'black' : ''">
            {{t.icon || i }}
          </v-icon>

          <v-badge v-if="t.badge > 0" color="warning" left bottom offset-y="2" offset-x="-2">
            <span slot="badge"> {{ t.badge }} </span>
          </v-badge>

          <template v-if="!$vuetify.breakpoint.smAndDown && t.name">
            {{ t.name || '' }}
          </template>
        </v-btn>

        <slot name="hint" >
          <base-dialog buttonText="" header="Information" iconName="mdi-information" info="Click on vertical dots for more function access" />
        </slot>
        <v-btn @click = "appBar=!appBar" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
        <v-back v-if="showBack" :title="backTitle"/>
</v-toolbar-items>
  <slot name="below"/>
</v-toolbar>

</v-container>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog.vue'
function cl(...args) {
  //console.log('MBT',...args)
}
export default {
    name: "BaseTool",
    components:{ BaseDialog},
    props:["toolList","toolbarName","loading","background","back","color","to","backTitle"],
    //components:{BaseBreakpointDisplay},
    data: () => ({
        appBar: false,
    }),
    computed: {
      grootGenoeg() {
        cl('grootGenoeg')
        return this.$vuetify.breakpoint.mobile && this.toolList.length
      },
      showBack() {
        if (this.back == undefined) return true
        if (this.back == true) return true
        return false
      },
      showImg() {
        cl('what is background?' , this.background)
        if (this.background == undefined) return true
        if (this.background == true || this.background == false) return this.background
        return false
      }
    },
    methods: {
      emitter(id) {
        cl('emitterA',id, this.toolList)
        let t = {}
        this.toolList.forEach(element => {
          if (element.id == id) t = element
        });
        //let t = this.toolList.find(t => t.id == id)
        if (!t) return
        cl('emitterB',t.task, t.id, t.name, t.icon)
        //this.$emit('toolClick',t.task||t.name||t.icon||i)
        this.$emit('toolClick',t)
        if (!t.name) {
          this.$emit('click'+t.icon, t)
        }
      },
      getColorMenu(t) {
        if (t == undefined) {
          cl('getColorMenu undefined')
          return ''
        }
        cl('getColorMenu',t,t.id)
        if (t.task && t.task == 'swop' && t.value == true) return 'red lighten-2'
        if (t.color) return t.color
        return ""
      },
      getColor(t) {
        cl('getColor')
        if (t.task && t.task == 'swop' && t.value == true) return 'red lighten-2'
        if (t.color) return t.color
        if (this.$vuetify.breakpoint.smAndDown) return "gray"
        return "blue lighten-1"  // "transparent"

      }
    },
    mounted() {
      cl('baseTool mounted')
      cl('baseTool mounted',this.toolbarName)
    }
}
</script>
<!--
  Emitted event will go thru as toolclick
  and will emit either task if give, or name, or icon otherwise button position
  So: If there is no data on button, a number from0 to however button there are will be emitted
  See: $emit('toolClick',t.task||t.name||t.icon||i)

  The button name will be replaced with and icon, or a number if size is smaller than grootGenoeg

  The color will be  "green" if no color is provided

  v-toolbar-items acts funny, so the buttons are not wrapped in it.

  Using a vertical menu does not want to work lekker, also could later fiddle with collapse on toolbar
  (don't think my users will understan collapse)
  -->
  <!-- Short description
#navbar
 <v-navigation-drawer>
   <v-list-item v-for="(t,i) in toolList" :key="t.name" @click="emitter(t.id?)">
      <v-list-item-title>  {{ t.name  || t.task || t.icon}}  {{  getColorMenu(t)  }} </v-list-item-title>
   </v-list-item>
      <slot name="menu" />
      <slot name="menu1" />
      <slot name="menu2" />
      <hr class="ma-2"/>
      <slot name="onlynav" />
 </v-navigation-drawer>
#toolbar
 <v-toolbar>
    <v-toolbar-items xv-if="toolList.length" class="ma-2 pa-2">
         <v-btn v-for="(t,i) in toolList" :key="t.name">
         <slot name="hint" />
         <slot name="menu" />
         <v-back v-if="showBack"/>
    </v-toolbar-items>
 </v-toolbar>
  -->
