<template>
    <v-card>

      <v-card-text v-if="access">
        Signature Example 2
       <a small class="ma-2" @click="hideBorder = !hideBorder" > hide/show Border </a>
     </v-card-text>

    <!-- <em>persitems = {{ backdoor }} --- {{  pers }}</em> -->
    <v-card-text xstyle="border-color: black;border-style: dashed;"
                 v-if="pers.items">
        <br>
    <!------------- SIGNATURE START   -->
    <table :border="hideBorder"
           :style="'padding:0px;width:' + signatureWidth + ';border-collapse:collapse;margin:0px;font-size: 10pt; font-family: Roboto, Verdana, sans-serif;'"
           cellpadding="0" cellspacing="0"
           :width="signatureWidth">

    <!-- letterhead top-->
    <tr>
        <td colspan="4" style="text-align: center;position: relative;top:+30px;">
         <center>
           <img style="position: relative;top:-30px;"
                align="center"
                title=" Hoërskool De Kuilen High School"
                src="https://www.kuiliesonline.co.za/img/letterhead-narrow.png"
                height="100">
         </center>
        </td>
    </tr>

    <!-- side part - information -->
    <tr>
        <td :rowspan="pers.items.length+3" style="text-align:center">
            <img style="margin-left: 10px; margin-right: 10px;margin-top: 10px;"
              title="Integrity, Respect, Equality"
              height="160" src="https://www.kuiliesonline.co.za/img/ire-down-space.png"
            >
        </td>
        <td colspan="2"></td>
        <td :rowspan="pers.items.length+3" style="text-align:center">
            <img style="margin-left: 10px; margin-right: 10px;margin-top: 10px;"
              title="Integrity, Respect, Equality"
              height="160" src="https://www.kuiliesonline.co.za/img/ire-down-space.png"
            >
        </td>
    </tr>

    <tr>
        <td :colspan=2 cellpadding="0" cellspacing="0"
             style="text-align:center;padding:0px"
             :style="insideColor"
        >
        <!-- 29 Aug Tanya will workarea onder naam sit. -->
            <h2 style="padding-top:18px;padding-bottom:0px"><em> {{ pers.public_preferredname }} </em></h2>
            <h3 style="padding-bottom:7px" v-if="pers.workarea">
            &nbsp;<h4><em>{{ pers.workarea }}</em></h4>
            </h3>
        </td>
    </tr>


    <tr v-for="(d,i) in pers.items" :key="i"
        style="font-size: 11pt; height:2em;"
       :style="insideColor"
    >
        <td  width="50%"
             style="margin-top:2px;padding-right:20px;text-align:right; line-height: 1.3em">
           {{ d.name }}
        </td>
        <td width="50%"
            style="margin:2px;padding-left:20px;text-align:left;font-weight: bolder;">
           {{ d.value }}
        </td>
    </tr>
    <tr style="xfont-size: 11pt; height:auto" :style="insideColor">
        <td  width="50%">&nbsp;</td>
        <td width="50%"></td>
    </tr>
    <tr>
        <td>v2</td>
        <td>
           {{ leftSideOfSocialIconLine }}
        </td>
        <td style="text-align:right;padding-top:5px"
            :xstyle="insideColor"
        >
          <socialIcons />
        </td>
    </tr>

    <tr v-if="lastLine.length">
        <td :colspan=4
             style="text-align:center"
        >
            {{ lastLine }}
        </td>
    </tr>
    <!-- Last part - addressline -->
    <tr>
    <td :colspan="4" width="100%">
      <center>
       <img  align="center" src="https://www.kuiliesonline.co.za/img/addressline.png"
             title="Address and PO Box"
             :width="signatureWidth">
      </center>
     </td>
    </tr>

    </table>
    <!------------- SIGNATURE END   -->

  </v-card-text>
</v-card>
</template>


<script>
import SocialIcons from './SocialIcons.vue'
export default {
    name:"Signature2",
    props: ['pers','access','backdoor'],
    components:{
        SocialIcons
    },
    data: () => ({
            hideBorder:false,
            signatureWidth: 610,
            socialIcons: 'icons',
            lastLine: '',
            leftSideOfSocialIconLine: '.'

    }),
    computed: {
        persItems() {
            console.log('this.pers.items compuited = ', this.pers.items)
            return this.pers.items
        },
        insideColor() {
            //return "background: linear-gradient(135deg, rgba(87,161,129,0.2861519607843137) 36%, rgba(88,158,72,1) 94%, rgba(0,255,25,1) 100%)"; //"background-color: orange;"  // was goldenrod
            //linear-gradient does not work in microsoft outlook for desktop..
            //return "background: rgba(87,161,129,0.2861519607843137);"
            //return "background-color: #EE6606;background: linear-gradient(to right, #32cd32, #f06d06)"
            return "background-color: #EE6606;background: linear-gradient(to right, #32cd32, #f06d06)"
            //return "background: #01893F;"
        },
    }
}
</script>