// import { gloGet } from "@/api/gloGet.js"
/*
    Use to load and save localStorage objects - fast way of getting them all, or saving them all
    I cannot get this thing to save to "saveTo", so i pass the value back = so not functions!!
    this.dinges = gloGet.save('test',zmlConfig)
    alert(JSON.stringify(this.dinges))
    this.dinges = gloGet.save('test',{heloow: 'hallo'})
    alert(JSON.stringify(this.dinges))
Usage:
 import { gloGet } from "@/api/gloGet.js"
 getZml.functions = gloGet.save('zmlFuncs', togetherFuncs)
 NB: Only using the save functionality at the moment...

*/

//import { mutations, getters } from "@/api/store";
import { ls } from "@/api/localStorage.js"
function cl(...args) {     console.log('glG',...args) }

const validVars = [
 'zmllogin'
,'zmlClassList'
,'zmlEventTypes'
,'zmlFuncs'
,'zmllogin'
,'zmlLookupPlace'
,'zmlMeritLevel'
,'zmlPersM'
,'zmlStudents'
,'zmlSubjects'
,'zmlTickList'
,'zmlVersion'
,'zmlClassList'
,'zmlEventTypes'
,'zmlFuncs'
,'zmlLookupPlace'
,'zmlMeritLevel'
,'zmlStudents'
,'zmlSubjects'
,'zmlTickList'
,'zmlVersion'
,'teacherToggleDisplay'
,'test']

//let getZml = getters.getState({ object: "gZml" })
//let mutateZml = mutations.setState({ object: "gZml", objectPath: [login, "grade"],value: 'hallo'});


export const gloGet = {
    someGlobals :  'hallo',
    loadingCache : false,
    loadingReal : false,
    save: (name, data, saveTo) => {
        cl('saving', name, data, data.length)
        if (!validVars.includes(name)) {
            alert(`We do not have ${name} in our list!`)
        }
        return gloGet.assign(name, data, saveTo)
    },
    assign: (name, data, saveTo) => {
        // cl('assign', name, data)
        ls.save(name, data)
        if (saveTo) saveTo = data
        return data

    },
    load: (name, data) => {
        cl('loading', name, data)
        if (!validVars.includes(name)) {
            alert(`We do not have ${name} in our list!`)
        }
        return gloGet.read(name, data)
    },
    read: (name, data) => {
        cl('reading', name, data, saveTo)
        if (ls.test(name)) return ls.load(name)
        return []

    },

}

