<template>
<svg xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    class="DekhsLogo"
    :view-box.camel="viewBox"
    :width="width"
    :height="height">
 <g id="Layer_x0020_1">
  <metadata id="dfsjrSuppie"/>
  <polygon id="TopRight" class="fil0" points="369,248 214,188 214,0 291,74 262,153 312,191 415,106 "/>
  <polygon id="TopLeft" class="fil1" points="59,248 214,188 214,0 137,74 166,153 116,191 13,106 "/>
  <path id="RightBadge" class="fil0" d="M415 289l-38 226c-3,14 -10,27 -20,37l-143 141 0 -462 201 58 0 0z"/>
  <path id="LeftBadge" class="fil1" d="M214 230l0 462 -143 -141c-10,-10 -17,-23 -20,-37l-38 -226 201 -58 0 0z"/>
  <path id="R" class="fil2" d="M20 756l4 0c4,0 7,-1 9,-2 2,-2 3,-4 3,-7 0,-3 -1,-5 -3,-7 -2,-2 -5,-2 -9,-2l-4 0 0 19zm43 42l-24 0 -19 -29 0 29 -20 0 0 -75 31 0c4,0 8,1 11,2 3,1 6,3 8,5 2,2 4,5 5,7 1,3 2,6 2,9 0,6 -1,10 -4,14 -3,4 -7,6 -12,7l24 31 0 0z"/>
  <polygon id="E1" class="fil2" points="114,738 91,738 91,751 113,751 113,768 91,768 91,781 114,781 114,797 71,797 71,722 114,722 "/>
  <path id="S" class="fil2" d="M169 741c-2,-2 -4,-3 -6,-4 -2,-1 -4,-1 -6,-1 -3,0 -5,1 -6,2 -2,1 -2,3 -2,5 0,1 0,2 1,3 1,1 2,2 3,2 1,1 3,1 4,2 2,0 3,1 5,2 6,2 11,5 14,9 3,4 4,8 4,14 0,4 -1,7 -2,10 -1,3 -3,6 -6,8 -3,2 -6,4 -9,5 -4,1 -8,2 -12,2 -10,0 -18,-3 -26,-9l8 -16c3,3 6,5 9,6 3,1 6,2 9,2 3,0 6,-1 7,-2 2,-1 2,-3 2,-5 0,-1 0,-2 -1,-3 0,-1 -1,-2 -2,-2 -1,-1 -2,-1 -4,-2 -2,-1 -3,-1 -5,-2 -3,-1 -5,-2 -7,-3 -2,-1 -5,-2 -7,-4 -2,-2 -3,-4 -5,-6 -1,-2 -2,-6 -2,-9 0,-4 1,-7 2,-10 1,-3 3,-6 5,-8 2,-2 5,-4 8,-5 3,-1 7,-2 11,-2 4,0 8,1 12,2 4,1 8,3 12,5l-8 15 0 0z"/>
  <path id="P" class="fil2" d="M212 756l7 0c7,0 11,-3 11,-9 0,-6 -4,-9 -11,-9l-7 0 0 19zm0 41l-20 0 0 -75 31 0c8,0 15,2 19,7 5,4 7,11 7,19 0,8 -2,14 -7,19 -5,4 -11,7 -19,7l-12 0 0 25 0 0z"/>
  <polygon id="E2" class="fil2" points="303,738 280,738 280,751 302,751 302,768 280,768 280,781 303,781 303,797 260,797 260,722 303,722 "/>
  <path id="C" class="fil2" d="M372 747c-5,-6 -10,-8 -17,-8 -3,0 -6,1 -8,2 -3,1 -5,3 -6,4 -2,2 -3,4 -4,7 -1,3 -2,5 -2,8 0,3 1,6 2,8 1,3 2,5 4,7 2,2 4,3 6,4 2,1 5,2 8,2 6,0 12,-3 17,-8l0 23 -2 1c-3,1 -6,2 -8,2 -3,1 -5,1 -8,1 -5,0 -10,-1 -15,-3 -5,-2 -9,-5 -13,-8 -4,-4 -7,-8 -9,-13 -2,-5 -3,-10 -3,-16 0,-6 1,-11 3,-16 2,-5 5,-9 9,-13 4,-3 8,-6 13,-8 5,-2 10,-3 15,-3 3,0 6,0 9,1 3,1 6,2 9,3l0 23 0 0z"/>
  <polygon id="T" class="fil2" points="413,738 413,797 393,797 393,738 377,738 377,722 429,722 429,738 " />
  <path id="RTop" class="fil3" d="M190 399l12 0c13,0 23,-3 30,-8 7,-5 10,-13 10,-23 0,-10 -3,-18 -10,-23 -7,-5 -17,-8 -30,-8l-12 0 0 63 0 0zm146 141l-82 0 -63 -98 0 98 -66 0 0 -255 103 0c14,0 27,2 37,6 11,4 19,10 26,17 7,7 12,16 16,25 3,9 5,20 5,30 0,19 -5,35 -14,47 -9,12 -23,20 -41,24l80 104 0 0z">
  </path>
 </g>
</svg>

</template>
<script>
export default {
    name:'DK-R-Logo',
    props: {
      width: {
        type: Number,
        default: 100
      },
      height: {
        type: Number,
        default: 100
      }
   },
   data: () => ({
      vbx: 0,
      vby: 0,
      vbw: 429,
      vbh: 800,
   }),
   computed: {
    viewBox() {
      const {vbx,vby,vbw,vbh} = this.$data;
      return [vbx,vby,vbw,vbh].join(' ');

    },
}
}
</script>
<style scoped>
    .fil3 {fill:#FEFEFE;fill-rule:nonzero}
    .fil2 {fill:#94469e;fill-rule:nonzero}
    .fil1 {fill:#F37435;fill-rule:nonzero}
    .fil0 {fill:#008052;fill-rule:nonzero}


.DekhsLogo {
  transform: rotateY(360deg);
  /* animation: turn 3.5s ease-out forwards 1s; */
  animation: fadeIn linear 7s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes turn {
  100% {
    transform: rotateY(0deg);
  }
}
</style>
