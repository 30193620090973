import { getters } from "@/api/store";
import { infoSnackbar, errorSnackbar } from '@/api/GlobalActions';
import { zFetch, zmlFetch } from '@/api/zmlFetch';
import { ls } from "@/api/localStorage.js"

let getZml = getters.getState({ object: "gZml" })

export const sbWork = {
    hello: (p1) => {console.log('hello from (SubjectWork.JS) ' , p1)}
   ,name: "SubjectWork"
   ,reportError: (response) => {
     if (response !== undefined && response.errorcode && response.errorcode != 0) {
      console.log(clWork.name,'DbErr:',response)
      if (response.error.indexOf('Duplicate entry') != -1 ) {
        errorSnackbar("ERROR : We use this item in this location - just update it")
      } else if (response.error.indexOf('a foreign key constraint') != -1 ) {
          errorSnackbar('Someone is using this information, we cannot delete it')
      } else {
        errorSnackbar("ERROR : " +  response.error)
      }
      return true
      }
      return false
   }
   ,showError: (response) => {
    console.log("ERROR : " +  response)
   }
   /*
    Pass in a grade and class - like G08A1 - and we return all classes from dkhs_rooster
   */
   ,getSubjectListsByGrade: (gradeclass, doneProcedure) => {
      let sn = {task: 'Dkhs_Learn - get Grade 8/9 subjects', data:{gradeclass: gradeclass, bind:{gradeclass: gradeclass}}}
      /*
      let sn = { task: 'plainSql' , sql:`\
      SELECT subject, subjectname, menemonic, teacher, public_preferredname, gradeclass\
      , venue, examdate, persid,id,listname \
      from (\
      SELECT DISTINCT l.shortname subject\
      , IFNULL( IF (SUBSTR(r.gradeclass, 4,1) = 'A',l.beskrywing,l.description) , r.subjectshortname) subjectname\
      , r.menemonic menemonic, p.initsurname teacher, p.public_preferredname\
      , r.gradeclass, 'none' venue, 'none' examdate, p.persid, 0 id, 'nl' listname\
      FROM dkhs_rooster r , dkhs_lsubject l, dkhs_personel p, dkhs_student s\
      WHERE r.gradeclass = CONCAT(s.grade,s.gclass)\
      AND s.studentid = ${studentid}\
      AND l.shortname = r.subjectshortname\
      AND r.menemonic = p.menemonic \
      UNION ALL\
      SELECT sub.shortname subject\
      , IF(SUBSTR(s.grade,1,1) = 'E', sub.description,sub.beskrywing) subjectname\
      , pp.menemonic, pp.initsurname teacher, pp.public_preferredname\
      , CONCAT(s.grade,s.gclass), cl.venue, cl.examdate, pp.persid, cl.id, cl.listname\
      FROM dkhs_student s\
       JOIN hw_classliststudent ss on s.studentid = ss.studentid\
       JOIN hw_classlist cl on ss.classlistid = cl.ckey\
       JOIN dkhs_lsubject sub on sub.subjectid = cl.hodsubjectid\
       LEFT JOIN dkhs_personel pp on pp.initsurname = cl.teacher\
      WHERE s.studentid = ${studentid}) uu\
      ORDER BY subject`}
      */

      zmlFetch(sn, doneProcedure)
   },
}

