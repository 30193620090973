//import Vue from 'vue'
import { zmlConfig } from '@/api/constants';
import { getters } from "@/api/store";
import { zmlFetch } from '@/api/zmlFetch';
import { ls } from "@/api/localStorage.js"
import { gloGet } from "@/api/gloGet.js"
import { zLoadCal } from "@/api/loadCalendar.js"
import { errorSnackbar, infoSnackbar } from "@/api/GlobalActions"

function cl(...args) {
    //console.log('zGB',...args)
}

let getZml = getters.getState({ object: "gZml" })

export const zData = {
    someGlobals :  'hallo',
    loadingCache : false,
    loadingReal : false,
    closeDate : null,
    loadSuper: (that) => {
        //Check on #super.user
        that.$super.user = getZml.login.superUser || false
        that.$super.username = getZml.login.username
        that.$super.fullname = getZml.login.fullname
        that.$super.userid  = getZml.login.userid
        that.$super.isAuthenticated = getZml.login.isAuthenticated
        console.log('that.$super', that.$super)
    },
    snack: (msg) => infoSnackbar(msg),
    loadSql(loading, sqlStatement, assignDataProc, api = zmlConfig.apiDKHS){
        loading = true
        let ts = {}
        ts.task = 'PlainSql'
        ts.sql = sqlStatement
        ts.api = api || zmlConfig.apiDKHS
        zmlFetch(ts, assignDataProc, errorLoading)
    },
    getSurveyCloseDate(loading) {
        zData.loading = loading
        let ts = {}
        ts.task = 'PlainSql'
        ts.sql = "select * from dkhs_constant where zmltype = 'SURVEY' and zmlname = 'CLOSEDATE'"
        ts.api = zmlConfig.apiDKHS
        zmlFetch(ts, finishedLoadingQuery, errorLoading)
    },
    sendEmail(emailInfo) {
        infoSnackbar('sending email')
        let email = emailInfo
        email.method = "advemail"
        if (!email.subject) email.subject  = "A Subject"
        if (!email.email_to) email.email_to = "faaktap@gmail.com"
        if (!email.htmlmessage) email.htmlmessage = '<h1> Hallo World </h1>'
        if (!email.email_cc) email.email_cc = ''
        if (!email.email_replyto) email.email_replyto = ''
        if (!email.test) email.test = "no"
        if (!email.email_from) email.email_from = "admin@kuiliesonline.co.za"
        if (!email.trusted_user) email.trusted_user = "info@kuiliesonline.co.za"
        let apiConfig = { method: 'POST'
                        , headers: { 'Accept': 'application/json'
                                   , api: zmlConfig.apiDKHS
                                   , 'Content-Type': 'application/json;charset=UTF-8'}
                        , body: JSON.stringify(email)
                        }
        fetch(zmlConfig.emailPath, apiConfig);
    },
    wernerTest(r) {cl('no func passed',r)},
    checkIfAllLoaded() {
        //infoSnackbar('Check System Properties 1')
        cl('checkIfAllLoaded 1 subj:', getZml.subjects.length)
        cl('checkIfAllLoaded 2 pers:', getZml.persMenemonic.length)
        cl('checkIfAllLoaded 3 func:', getZml.functions.length)
        cl('checkIfAllLoaded 4 plac:', getZml.place.length)
        cl('checkIfAllLoaded 5 clst:', getZml.classList.length)
        cl('checkIfAllLoaded 6 tick:', getZml.tickList.length)
        cl('checkIfAllLoaded 7 tick:', getZml.students.length)

        if (getZml.subjects.length
         && getZml.persMenemonic.length
         && getZml.functions.length
         && getZml.place.length
         && getZml.classList.length
         && getZml.students.length
         && getZml.tickList.length) {
            return true
         } else {
            return false
         }
    },
    quickLoadInitialData: (whatever, afterwardsFunction) => {
        //infoSnackbar('Check System Properties 2')
        if (zData.checkIfAllLoaded() == true) {
            cl('quickLoadInitialData----------------NotNeeded : ',zData.loadingCache, whatever)
            if (afterwardsFunction) afterwardsFunction()
            return
        }
        if (zData.loadingCache == true) {
            if (afterwardsFunction) afterwardsFunction()
            cl('quickLoadInitialData----------------Other One Busy')
            return
        }
        zData.loadingCache = true
        console.time('quickload')
        cl('quickLoadInitialData---------------------------------------------------Start',zData.loadingCache, whatever)
        getZml.subjects = ls.load('zmlSubjects')
        getZml.functions = ls.load('zmlFuncs')
        getZml.persMenemonic = ls.load('zmlPersM')
        getZml.place = ls.load('zmlLookupPlace')
        getZml.classList = ls.load('zmlClassList')
        getZml.tickList = ls.load('zmlTickList')
        getZml.students = ls.load('zmlStudents')
        zData.loadingCache = false
        cl('quickLoadInitialData------------------------------------------------End',zData.loadingCache, whatever)
        console.timeEnd('quickload')

        if (zData.checkIfAllLoaded() == false) {
            //We still gave a problem, so we will load real data from db.
            getZml.subjects = []
            zData.initialData('cache is empty', afterwardsFunction)
        } else {
            if (afterwardsFunction) afterwardsFunction()
        }
    },
    initialData:  (whatever, afterwardsFunction) => {
        //Check if a loading is not already busy
        if (zData.loadingReal == true) {
            if (afterwardsFunction) afterwardsFunction()
            return
        }
        infoSnackbar('Refreshing System Properties')
        zData.loadingReal = true
        cl('InitialData-------------------------------------------------------------------Start',zData.loadingReal, whatever)
        if (whatever !== undefined) cl(whatever)

        if (!getZml.subjects || getZml.subjects.length == 0) {
            console.time('bigload')
            // Her we fetch all lookup data from mysql & php
            const ts = {}
            ts.api = zmlConfig.apiDKHS
            ts.task = 'loadlearn'
            ts.data = {}
            ts.data.language = getZml.login.lang
            ts.data.studentid = getZml.login.studentid
            ts.data.logintype = getZml.login.type

            zmlFetch(ts, finishedLoadingBasic, errorLoading)
        } else {
            if (afterwardsFunction) afterwardsFunction()
        }
        return "something";
    },
    calendarData: (whatever) => {
        cl('Calling OUR TESTER')
        infoSnackbar('Refreshing Calendar 1')
        //zLoadCal.calendarData('Load From zData')
        zLoadCal.calendarData(whatever || 'loading base')
        cl('DONE Calling OUR TESTER')
    },
    baseCalendarData: (whatever) => {
        infoSnackbar('Refreshing Calendar 2')
        cl('Calling OUR TESTER')
        //zLoadCal.calendarData('Load From zData')
        zLoadCal.getBaseCalendar(whatever || 'loading base')
        cl('DONE Calling OUR TESTER')
    },
    randomChuckNorris: async () => {
        let response = await fetch('https://api.chucknorris.io/jokes/random')
        let data = await response.json()
        cl('chuck data', data.value)
        return data
    }

}

//----------------------------------------------------------------
function finishedLoadingBasic (response) {
    zData.loadingReal = false
    cl('InitialData-------------------------------------------------------------------End',zData.loadingReal)
    console.timeEnd('bigload')
    //getZml = getters.getState({ object: "gZml" })
    cl('finishedLoadingBasic', response)

    // will be empty when not a student
    if (response.student.length) {
        getZml.login.grade = response.student[0].grade;
        getZml.login.gclass = response.student[0].gclass;
    }


    // add in a proper way - "other at the bottom
    let f1 = response.functions.filter(e => e.functionaccess == 'other')
    let f2 = response.functions.filter(e => e.functionaccess != 'other')
    let togetherFuncs = f2.concat(f1)
    getZml.functions = gloGet.save('zmlFuncs', togetherFuncs)
    cl('functions saved on inital load:::', getZml.functions)

    if (response.subjects.length > 1) {
      getZml.subjects = gloGet.save('zmlSubjects', response.subjects)
    } else {
        alert('no subjects retrieved!!')
    }

    if (response.pers.length > 1) {
      //initSurname was added - to try and link up with CEMIS
      getZml.persMenemonic = gloGet.save('zmlPersM', response.pers)
      cl('pers = ', getZml.persMenemonic)
    }

    getZml.place = gloGet.save('zmlLookupPlace',response.place)

    getZml.students = gloGet.save('zmlStudents',response.students)

    // getZml.meritlevel = gloget....// xmySet('gZml',"meritLevel", meritlevel )
    // ls.save('zmlMeritLevel', response.meritlevel)

    if (response.classlist.length > 1) {
        getZml.classList = gloGet.save('zmlClassList',response.classlist)
    }
    if (response.ticklist && response.ticklist.length > 0) {
        let tmpList = []
        response.ticklist.forEach(e => {
            const tickObj = JSON.parse(e.jdocstructure)
            tmpList.push(tickObj)
        });
        getZml.tickList = gloGet.save('zmlTickList', tmpList)
    } else {
        alert('ticklist is empty!!!')
    }
    if (zData.checkIfAllLoaded() == false) {
        alert('We still have a problem - not all data was loaded - please contact Werner or Tanya')
    }

    zData.wernerTest()
    cl('InitialData-----------------------------------------------------------DONE LOADING BACKGROUND DATA')

}


//----------------------------------------------------------------
function errorLoading (response) {
    zData.loadingReal = false
    //alert('We had an error loading your data!')
    errorSnackbar('We had an error loading your data! - Possible Internet Disruption')
    l('We had an ERROR loading your data!',response)
}

//----------------------------------------------------------------
function finishedLoadingQuery (response) {
     zData.loading = false
     zData.closeDate = response[0].zmlvalue
}